import { APIService } from './../authentication/APIService'

export default {
    getDeliveryZones(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/delivery-zones?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getDeliveryZone(deliveryZoneId) {
        return APIService.get("api/staff/delivery-zones/" + deliveryZoneId);
    },

    addDeliveryZone(payload) {
        return APIService.post("api/staff/delivery-zones", payload);
    },

    updateDeliveryZone(deliveryZoneId, payload) {
        return APIService.patch("api/staff/delivery-zones/" + deliveryZoneId, payload);
    },
}