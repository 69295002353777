import { APIService } from './../authentication/APIService'

export default {
    getColours(
        page = 1,
        pageSize = 12,
    ) {
         // Generate url
         let url = 'api/staff/colours?';

         // Mandatory parameters
         url += 'page=' + page + '&';
         url += 'per_page=' + pageSize;
 
         return APIService.get(url);
    },

    getColour(colourId) {
        return APIService.get("api/staff/colours/" + colourId);
    },

    addColour(payload) {
        return APIService.post("api/staff/colours", payload);
    },

    updateColour(colourId, payload) {
        return APIService.patch("api/staff/colours/" + colourId, payload);
    },
}