import { APIService } from './../authentication/APIService'

export default {
    getProducts(
        page = 1,
        pageSize = 12,
        search = '',
    ) {
        // Generate url
        let url = 'api/staff/products?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        if (search) {
            url += '&search=' + search;
        }

        return APIService.get(url);
    },

    getProduct(productId) {
        return APIService.get("api/staff/products/" + productId);
    },

    addProduct(payload) {
        return APIService.post("api/staff/products", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    updateProduct(productId, payload) {
        return APIService.patch("api/staff/products/" + productId, payload);
    },

    deleteProduct(productId) {
        return APIService.delete("api/staff/products/" + productId);
    },

    addProductVariations(productId, payload) {
        return APIService.post("api/staff/products/" + productId + "/variations", payload);
    },

    updateProductVariations(productId, payload) {
        return APIService.patch("api/staff/products/" + productId + "/variations", payload);
    },

    addProductImages(productId, payload) {
        return APIService.post("api/staff/products/" + productId + "/images", payload);
    },

    deleteProductImage(productId, imageId) {
        return APIService.delete("api/staff/products/" + productId + "/images/" + imageId);
    },
}