<template>
  <div>
    <loader v-if="loading.page"> </loader>
    <div v-else>
      <h1 :class="'playfair-display text-center mt-4 mb-4'">Our Catalog</h1>
      <div class="row justify-content-center mb-5">
        <div class="col-md-7">
          <div class="row justify-content-center">
            <a
              :href="`/shop?category=${category.id}`"
              class="col-5 col-lg-5 m-2 bg-grey collection text-center fc-white"
              v-for="category in categories"
              :key="category.id"
              :style="{
                textDecoration: `none`,
                backgroundImage: `url(${
                  category.banner_image != null
                    ? category.banner_image.storage_link
                    : ''
                })`,
                backgroundColor: category.banner_image != null
                    ? ''
                    : '#000000 !important'
              }"
            >
              <div>
                <span class="colletion-text">{{ category.name }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import ShopService from "@/services/customer/ShopService";
import Loader from "@/components/Loader.vue";

export default {
  name: "CollectionView",
  components: {
    Loader,
  },
  data() {
    return {
      loading: {
        page: true,
      },
      categories: [],
    };
  },
  mounted() {
    ShopService.getCategories().then((response) => {
      this.categories = response.data.categories.data;
    });

    this.loading.page = false;
  },
};
</script>