<template>
  <div id="app">
    <div class="main">
      <main-header
        :cart-count="cartCount"
        :wishlist-count="wishlistCount"
      ></main-header>
      <staff-header
        v-if="
          $store.getters['user/object'] &&
          ['MANAGER', 'ADMINISTRATOR', 'SUPER_ADMINISTRATOR'].includes(
            $store.getters['user/object'].role_code
          )
        "
      ></staff-header>
      <router-view
        @refreshCartCount="getCartCount"
        @refreshWishlistCount="getWishlistCount"
        :class="
          'view ' +
          ($route.name == 'home' || $route.name == 'checkout' ? 'pb-0' : 'pb-5')
        "
      />
    </div>

    <main-footer></main-footer>
  </div>
</template>

<script>
import MainHeader from "@/components/layout/navigation/MainHeader.vue";
import StaffHeader from "@/components/layout/navigation/StaffHeader.vue";
import MainFooter from "@/components/layout/MainFooter.vue";
import CartService from "./services/customer/CartService";
import WishlistService from "./services/customer/WishlistService";

export default {
  name: "HomeView",
  components: {
    MainHeader,
    MainFooter,
    StaffHeader,
  },
  data() {
    return {
      cartCount: 0,
      wishlistCount: 0,
    };
  },
  mounted() {
    this.getCartCount();
    this.getWishlistCount();
  },
  methods: {
    getCartCount() {
      CartService.getCartItems().then((response) => {
        this.cartCount = response.data.cart_items.data.length;
      });
    },
    getWishlistCount() {
      WishlistService.getWishlistItems().then((response) => {
        this.wishlistCount = response.data.wishlist_items.data.length;
      });
    },
  },
};
</script>

<style>
@import "@/assets/styles/main.css";
</style>
