import { CaretakerApiService } from '../authentication/CaretakerAPIService';
import { APIService } from './../authentication/APIService';
import store from '@/store';

export default {
    getWishlistItems() {
        if (store.getters['user/object']) {
            return APIService.get("api/wishlist");
        } else {
            return CaretakerApiService.get("api/wishlist");
        }
    },

    addToWishlist(payload) {
        if (store.getters['user/object']) {
            return APIService.post("api/wishlist", payload);
        } else {
            return CaretakerApiService.post("api/wishlist", payload);
        }
    },

    deleteWishlistItem(productId) {
        if (store.getters['user/object']) {
            return APIService.delete("api/wishlist/" + productId);
        } else {
            return CaretakerApiService.delete("api/wishlist/" + productId);
        }
    },
}