import { CaretakerApiService } from '../authentication/CaretakerAPIService';
import { APIService } from './../authentication/APIService';
import store from '@/store';

export default {
    getProducts(
        page = 1,
        pageSize = 12,
        search = '',
        categories = [],
        sizes = [],
        colours = [],
        // price = [],
        sort_column = '',
        sort_direction = '',
    ) {
        // Generate url
        let url = 'api/products?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        if (search) {
            url += '&search_text=' + search;
        }

        if (categories.length > 0) {
            for (let index = 0; index < categories.length; index++) {
                url = url + "&categories[]=" + categories[index];

            }
        }

        if (sizes.length > 0) {
            for (let index = 0; index < sizes.length; index++) {
                url = url + "&sizes[]=" + sizes[index];
            }
        }

        if (colours.length > 0) {
            for (let index = 0; index < colours.length; index++) {
                url = url + "&colours[]=" + colours[index];
            }
        }

        if (sort_column != '' && sort_direction != '') {
            url = url + "&order_column=" + sort_column + "&order_direction=" + sort_direction;
        }

        if (store.getters['user/object']) {
            return APIService.get(url);
        } else {
            return CaretakerApiService.get(url);
        }
    },

    getProduct(productId) {
        if (store.getters['user/object']) {
            return APIService.get("api/products/" + productId);
        } else {
            return CaretakerApiService.get("api/products/" + productId);
        }
    },

    getCategories() {
        return CaretakerApiService.get("api/categories");
    },

    getSizes() {
        return CaretakerApiService.get("api/sizes");
    },

    getColours() {
        return CaretakerApiService.get("api/colours");
    }
}