import { APIService } from './../authentication/APIService'

export default {
    getCoupons(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/coupons?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getCoupon(couponId) {
        return APIService.get("api/staff/coupons/" + couponId);
    },

    addCoupon(payload) {
        return APIService.post("api/staff/coupons", payload);
    },

    updateCoupon(couponId, payload) {
        return APIService.patch("api/staff/coupons/" + couponId, payload);
    },
}