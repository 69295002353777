<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="New Country"
          :return_button="true"
          return_link="/staff/countries"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="name" class="form-label">Code</label>
              <input
                type="text"
                class="form-control"
                id="code"
                placeholder="Enter ISO2 Country code. E.g GH"
                v-model="form.code"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="email" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter Country name. E.g Ghana"
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Add Country" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import CountryService from "@/services/staff/CountryService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddCountryView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        code: "",
        name: "",
      },
      success: "",
      errors: [],
    };
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      CountryService.addCountry(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.form.code = "";
          this.form.name = "";
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
