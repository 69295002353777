<template>
  <div class="mb-5">
    <loader v-if="loading.page"></loader>
    <div v-else-if="cart_items.length == 0">
      <div class="row mt-10 text-center">
        <i class="bi bi-cart mb-3 h1"></i>
        <h1
          class="mb-3"
          style="
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: 0.1rem;
          "
        >
          Your Cart Is Empty
        </h1>
        <div>
          <a href="/shop">
            <button type="button" class="btn btn-dark">
              <b style="font-weight: bolder; text-transform: uppercase"
                >Browse Our Store Now</b
              >
            </button>
          </a>
        </div>
      </div>
    </div>
    <div v-else class="row mt-5 justify-content-center">
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-12">
            <div class="justify-content-center text-center">
              <h1
                class="playfair-display"
                style="font-size: 2rem; font-style: italic"
              >
                In Your Bag
              </h1>
              <div class="justify-content-center" style="font-size: 0.9rem">
                <nav
                  class=""
                  style="--bs-breadcrumb-divider: '>'"
                  aria-label="breadcrumb"
                >
                  <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item">Cart</li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Information
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Pay
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Order Complete
                    </li>
                  </ol>
                </nav>
              </div>

              <div class="row mt-4">
                <div v-if="success" class="alert alert-success" role="alert">
                  {{ success }}
                </div>
                <div
                  v-if="errors.length > 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </div>
                <div
                  class="col-lg-12 col-12 text-start"
                  v-for="(item, index) in cart_items"
                  :key="item.id"
                >
                  <cart-item
                    :item="item"
                    @remove="removeFromCart(item.id)"
                  ></cart-item>
                  <hr v-if="index != cart_items.length - 1" />
                </div>
                <a href="/checkout" class="p-0 mt-5">
                  <button type="button" class="btn btn-lg btn-dark">
                    <b style="font-weight: bolder"
                      >Next : Submit Information & Checkout</b
                    >
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import Loader from "@/components/Loader.vue";
import { formatErrors } from "@/utils/helpers";
import CartItem from "@/components/shop/CartItem.vue";
import CartService from "@/services/customer/CartService";

export default {
  name: "CartView",
  components: {
    Loader,
    CartItem,
  },
  data() {
    return {
      cart_items: [],
      loading: {
        page: true,
      },
      success: "",
      errors: [],
    };
  },
  mounted() {
    CartService.getCartItems().then((response) => {
      this.cart_items = response.data.cart_items.data;
      this.loading.page = false;
    });
  },

  methods: {
    removeFromCart(cartItemId) {
      CartService.deleteCartItem(cartItemId)
        .then((response) => {
          this.success = response.data.message;
          CartService.getCartItems().then((response) => {
            this.cart_items = response.data.cart_items.data;
            this.loading.page = false;
          });
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
          this.$emit('refreshCartCount');
        });
    },
  },
};
</script>