
import store from "@/store";

export const getError = (error) => {
    const errorMessage = "API Error, please try again.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        console.error(`API ${error.config.url} not found`);
        return errorMessage;
    }
    if (process.env.NODE_ENV === "development") {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
    }
    if (error.response.data && error.response.data.errors) {
        return error.response.data.errors;
    }

    return errorMessage;
};

export const formatErrors = (error) => {
    let errors = [];

    if (typeof error.response.data.error !== 'undefined') {
        errors[0] = error.response.data.error;
    }

    else if (typeof error.response.data.errors !== 'undefined') {
        const keys = Object.keys(error.response.data.errors);
        keys.forEach((key, index) => {
            errors[index] = error.response.data.errors[key][0];
        });
    }

    else if (typeof error.response.data.message !== 'undefined') {
        errors[0] = error.response.data.message;
    }

    return errors;
};

export const userHasClearance = (clearanceLevel) => {
    const authenticatedUser = store.getters["user/object"];

    if (!authenticatedUser) {
        return false
    }

    if (authenticatedUser.role.id >= clearanceLevel) {
        return true
    }

    return false;
};

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}