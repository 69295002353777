<template>
  <div class="row justify-content-center mt-5 mb-5">
    <div class="col-md-4 text-center">
      <h1 class="playfair-display mb-3">Shipping Policy</h1>
      <p class="p-0 text-start">
        Note! Delivery is usually done
        <b>within 36 working hours</b> after your order is placed. If for any
        reason we can't deliver within the stated period, you will be informed.
      </p>
      <p class="p-0 text-start">
        However, for emergency situations, we strongly advise that you choose
        the in-store pick up option and send a rider to pick up for you an hour
        after the order is placed or call us to arrange for delivery.
      </p>
    </div>
  </div>
</template>
      
    <script>
export default {
  name: "ShippingView",
  components: {},
};
</script>