<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="Record Transaction"
          :return_button="true"
          return_link="/staff/accounts"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="account_code" class="form-label">Account</label>
              <select
                v-model="form.account_code"
                id="account_code"
                class="form-select form-select-sm"
                :disabled="loading.form"
              >
                <option
                  v-for="account in accounts"
                  :key="account.code"
                  :value="account.code"
                >
                  {{ account.name }}
                </option>
              </select>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="direction" class="form-label">Direction</label>
                  <select
                    v-model="form.direction"
                    id="direction"
                    class="form-select form-select-sm"
                    :disabled="loading.form"
                  >
                    <option value="Cr">Credit</option>
                    <option value="Dr">Debit</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="amount" class="form-label">Amount</label>
                  <input
                    type="text"
                    class="form-control"
                    id="amount"
                    placeholder="0.00."
                    v-model="form.amount"
                    :disabled="loading.form"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="description" class="form-label">Description</label>
              <input
                type="text"
                class="form-control"
                id="description"
                placeholder="Describe the transaction."
                v-model="form.description"
                :disabled="loading.form"
              />
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                :class="
                  'btn mt-2 w-100 ' +
                  (form.direction == 'Cr'
                    ? 'btn-outline-success'
                    : 'btn-outline-danger')
                "
                :disabled="loading.form"
              >
                {{
                  loading.form
                    ? "Loading, please wait..."
                    : "Record Transaction"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import AccountService from "@/services/staff/AccountService";
import TransactionService from "@/services/staff/TransactionService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddTransactionView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        account_code: "",
        direction: "Cr",
        amount: "",
        description: "",
      },
      success: "",
      errors: [],
      accounts: [{ name: "Kindly select an account.", code: "" }],
    };
  },
  mounted() {
    AccountService.getAccounts().then((response) => {
      this.accounts = [
        this.accounts[0],
        ...response.data.accounts.data.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name} - GH₵ ${item.balance}`,
          };
        }),
      ];
    });
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      if (this.form.direction == "Dr") {
        this.form.amount = this.form.amount * -1;
      }
      TransactionService.recordTransaction(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.form.account_code = "";
          this.form.direction = "Cr";
          this.form.amount = "";
          this.form.description = "";
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
