import { APIService } from './../authentication/APIService'

export default {
    getRoles() {
        return APIService.get("api/staff/roles");
    },

    getUsers(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/users?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getUser(userId) {
        return APIService.get("api/staff/users/" + userId);
    },

    addUser(payload) {
        return APIService.post("api/staff/users", payload);
    },

    updateUser(userId, payload) {
        return APIService.patch("api/staff/users/" + userId, payload);
    },
}