<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="Create Your Account"
          :center_title="false"
          :return_button="false"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-4">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="name" class="form-label">Enter your Name.</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Kindly enter your full name"
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="email" class="form-label">Enter your Email.</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="name@example.com"
                v-model="form.email"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="phone" class="form-label">Enter your Phone.</label>
              <input
                type="test"
                class="form-control"
                id="phone"
                placeholder="name@example.com"
                v-model="form.phone"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="password" class="form-label"
                >Enter your Password.</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="***********"
                v-model="form.password"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="password_confirmation" class="form-label"
                >Re-Enter your Password.</label
              >
              <input
                type="password"
                class="form-control"
                id="password_confirmation"
                placeholder="***********"
                v-model="form.password_confirmation"
                :disabled="loading.form"
              />
            </div>
            <button
              @click.prevent="onSubmit"
              type="button"
              class="btn btn-dark mt-2 w-100 box-shadow-light"
              :disabled="loading.form"
            >
              {{ loading.form ? "Loading, please wait..." : "Register" }}
            </button>
            <div class="mt-3 text-center" style="font-size: 0.9rem">
              Already registered? <br />
              <a href="/login"
                ><span style="color: black; font-weight: bold"
                  >Login here.</span
                ></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "RegisterView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      success: "",
      errors: [],
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;

      let action = "";
      let payload = {};
      action = "user/register";
      payload = {
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      };

      // Process submission
      // eslint-disable-next-line no-unused-vars
      this.$store
        .dispatch(action, payload)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          let route = "";

          this.success = "Registeration successful.";

          this.$emit("refreshWishlistCount");
          this.$emit("refreshCartCount");

          // redirect to home or redirect route if set.
          if (this.$route.query.redirect) {
            route = this.$route.query.redirect;
          } else {
            route = "/";
          }

          this.$router.push(route);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422 || error.response.status === 401) {
            let scope = this;
            scope.errors = formatErrors(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>