<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="View Colour"
          :return_button="true"
          return_link="/staff/colours"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <label for="name" class="form-label">Code</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="code"
                placeholder="Enter 6 digit code. E.g FFFFFF"
                aria-describedby="colour-code"
                v-model="form.code"
                :disabled="true"
              />
              <span class="input-group-text" id="colour-code"
                ><div
                  class="box-shadow-light"
                  :style="`width: 20px; height: 15px; background-color: #${form.code};`"
                ></div>
              </span>
            </div>
            <div class="form-group mt-2">
              <label for="email" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter colour name. E.g Blue"
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Update Colour" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import ColourService from "@/services/staff/ColourService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddColourView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        code: "",
        name: "",
      },
      success: "",
      errors: [],
    };
  },
  mounted() {
    ColourService.getColour(this.$route.params.id).then((response) => {
      const colour = response.data.colour;
      this.form.code = colour.code;
      this.form.name = colour.name;
    });
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      ColourService.updateColour(this.$route.params.id, this.form)
        .then((response) => {
          this.success = response.data.message;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
