import { CaretakerApiService } from '../authentication/CaretakerAPIService';
import { APIService } from './../authentication/APIService'
import store from '@/store';

export default {
    getCartItems() {
        if (store.getters['user/object']) {
            return APIService.get("api/cart");
        } else {
            return CaretakerApiService.get("api/cart");
        }
    },

    addToCart(payload) {
        if (store.getters['user/object']) {
            return APIService.post("api/cart", payload);
        } else {
            return CaretakerApiService.post("api/cart", payload);
        }
    },

    updateCartItem(cartItemId, payload) {
        if (store.getters['user/object']) {
            return APIService.patch("api/cart/" + cartItemId, payload);
        } else {
            return CaretakerApiService.patch("api/cart/" + cartItemId, payload);
        }
    },

    deleteCartItem(cartItemId) {
        if (store.getters['user/object']) {
            return APIService.delete("api/cart/" + cartItemId);
        } else {
            return CaretakerApiService.delete("api/cart/" + cartItemId);
        }
    },
}