import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import NotFoundView from '@/views/NotFoundView.vue';
import ListAddressesView from '@/views/customers/account-area/addresses/ListAddressesView.vue';
import ViewUserView from '@/views/staff/users/ViewUserView.vue';
import ListUsersView from '@/views/staff/users/ListUsersView.vue';
import AddUserView from '@/views/staff/users/AddUserView.vue';
import ListTransactionsView from '@/views/staff/accounts/ListTransactionsView.vue';
import AddTransactionView from '@/views/staff/accounts/AddTransactionView.vue';
import AddCategoryView from '@/views/staff/categories/AddCategoryView.vue';
import ListCategoriesView from '@/views/staff/categories/ListCategoriesView.vue';
import ViewCategoryView from '@/views/staff/categories/ViewCategoryView.vue';
import AddColourView from '@/views/staff/colours/AddColourView.vue';
import ListColoursView from '@/views/staff/colours/ListColoursView.vue';
import ViewColourView from '@/views/staff/colours/ViewColourView.vue';
import AddCountryView from '@/views/staff/countries/AddCountryView.vue';
import ListCountriesView from '@/views/staff/countries/ListCountriesView.vue';
import ViewCountryView from '@/views/staff/countries/ViewCountryView.vue';
import AddCouponView from '@/views/staff/coupons/AddCouponView.vue';
import StaffListCouponsView from '@/views/staff/coupons/ListCouponsView.vue';
import ViewCouponView from '@/views/staff/coupons/ViewCouponView.vue';
import AddDeliveryZoneView from '@/views/staff/delivery-zones/AddDeliveryZoneView.vue';
import ListDeliveryZonesView from '@/views/staff/delivery-zones/ListDeliveryZonesView.vue';
import ViewDeliveryZoneView from '@/views/staff/delivery-zones/ViewDeliveryZoneView.vue';
import AddOrderView from '@/views/staff/orders/AddOrderView.vue';
import StaffListOrdersView from '@/views/staff/orders/ListOrdersView.vue';
import StaffViewOrderView from '@/views/staff/orders/ViewOrderView.vue';
import ListProductsView from '@/views/staff/products/ListProductsView.vue';
import AddProductView from '@/views/staff/products/AddProductView.vue';
import StaffListRefundsView from '@/views/staff/refunds/ListRefundsView.vue';
import StaffViewRefundView from '@/views/staff/refunds/ViewRefundView.vue';
import AddRegionView from '@/views/staff/regions/AddRegionView.vue';
import ListRegionsView from '@/views/staff/regions/ListRegionsView.vue';
import ViewRegionView from '@/views/staff/regions/ViewRegionView.vue';
import ListSettingsView from '@/views/staff/settings/ListSettingsView.vue';
import AddSettingView from '@/views/staff/settings/AddSettingView.vue';
import ViewSettingView from '@/views/staff/settings/ViewSettingView.vue';
import AddSizeView from '@/views/staff/sizes/AddSizeView.vue';
import ListSizesView from '@/views/staff/sizes/ListSizesView.vue';
import ViewSizeView from '@/views/staff/sizes/ViewSizeView.vue';
import ListCouponsView from '@/views/customers/account-area/coupons/ListCouponsView.vue';
import ListOrdersView from '@/views/customers/account-area/orders/ListOrdersView.vue';
import LoginView from '@/views/authentication/LoginView.vue';
import RegisterView from '@/views/authentication/RegisterView.vue';
import ForgotPasswordView from '@/views/authentication/ForgotPasswordView.vue';
import AddAddressView from '@/views/customers/account-area/addresses/AddAddressView.vue';
import UpdateAddressView from '@/views/customers/account-area/addresses/UpdateAddressView.vue';
import ViewOrderView from '@/views/customers/account-area/orders/ViewOrderView.vue';
import AddRefundView from '@/views/customers/account-area/refunds/AddRefundView.vue';
import ListRefundsView from '@/views/customers/account-area/refunds/ListRefundsView.vue';
import ViewRefundView from '@/views/customers/account-area/refunds/ViewRefundView.vue';
import CartView from '@/views/customers/CartView.vue';
import WishlistView from '@/views/customers/WishlistView.vue';
import CheckoutView from '@/views/customers/CheckoutView.vue';
import CollectionView from '@/views/customers/CollectionView.vue';
import ShopView from '@/views/customers/ShopView.vue';
import ProductView from '@/views/customers/ProductView.vue';
import ViewProductView from '@/views/staff/products/ViewProductView.vue';
import ContactView from '@/views/ContactView.vue';
import ShippingView from '@/views/ShippingView.vue';
import ReturnsView from '@/views/ReturnsView.vue';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'The Jeans Shop GH';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Welcome',
      description: 'Retailers of Quality & Afforable Jeans',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFoundView,
    meta: {
      title: 'Page not found',
      description: 'Oops, we can\'t seem to find this.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      title: 'Contact Us',
      description: 'Get in touch with us.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/policy/returns',
    name: 'returns',
    component: ReturnsView,
    meta: {
      title: 'Returns Policy',
      description: 'Return a defective item.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/policy/shipping',
    name: 'shipping',
    component: ShippingView,
    meta: {
      title: 'Shipping Policy',
      description: 'How we get your orders to you.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: 'About Us',
      description: 'We believe that every individual deserves to feel confident and stylish in what they wear.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/page-not-found',
    name: 'page-not-found',
    component: NotFoundView,
    meta: {
      title: 'Page Not Found',
      description: 'The requested page cannot be found.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Authentication
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: 'Login',
      description: 'Log into your Account.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      title: 'Register',
      description: 'Register for an Account.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/forgot-passwrd',
    name: 'forgot-password',
    component: ForgotPasswordView,
    meta: {
      title: 'Forgot Password',
      description: 'Reset your forgotten password.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Account Area - Addresses
  {
    path: '/account-area/addresses',
    name: 'list-addresses',
    component: ListAddressesView,
    meta: {
      title: 'Your Addresses',
      description: 'View and manage your addresses.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/account-area/addresses/add',
    name: 'add-address',
    component: AddAddressView,
    meta: {
      title: 'Add Address',
      description: 'Add Address.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/account-area/addresses/:id/update',
    name: 'update-address',
    component: UpdateAddressView,
    meta: {
      title: 'Update Address',
      description: 'Update Address.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Account Area - Coupons
  {
    path: '/account-area/coupons',
    name: 'list-coupons',
    component: ListCouponsView,
    meta: {
      title: 'Coupons',
      description: 'View and manage your coupons.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Account Area - Orders
  {
    path: '/account-area/orders',
    name: 'list-orders',
    component: ListOrdersView,
    meta: {
      title: 'Your Orders',
      description: 'View and manage your orders.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/account-area/orders/:id/view',
    name: 'view-order',
    component: ViewOrderView,
    meta: {
      title: 'View Order',
      description: 'View Order.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Acount Area - Refunds
  {
    path: '/account-area/refunds/:orderId/add',
    name: 'add-refund',
    component: AddRefundView,
    meta: {
      title: 'Request Refund',
      description: 'Request a Refund.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/account-area/refunds',
    name: 'list-refunds',
    component: ListRefundsView,
    meta: {
      title: 'Your Refunds',
      description: 'View and manage your Refunds.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },
  {
    path: '/account-area/refunds/:id/view',
    name: 'view-refund',
    component: ViewRefundView,
    meta: {
      title: 'Your Refund',
      description: 'View Refund.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Cart
  {
    path: '/cart',
    name: 'cart',
    component: CartView,
    meta: {
      title: 'Your Cart',
      description: 'View and manage your Cart.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Wishlist
  {
    path: '/wishlist',
    name: 'wishlist',
    component: WishlistView,
    meta: {
      title: 'Your Wishlist',
      description: 'View and manage your Wishlist.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Checkout
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    meta: {
      title: 'Checkout',
      description: 'Checkout',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Collection
  {
    path: '/collections',
    name: 'collections',
    component: CollectionView,
    meta: {
      title: 'Our Collections',
      description: 'Browse our Collections.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Shop
  {
    path: '/shop',
    name: 'shop',
    component: ShopView,
    meta: {
      title: 'Shop',
      description: 'Browse our Products.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Customers - Product
  {
    path: '/shop/product/:id/:slug',
    name: 'view-product',
    component: ProductView,
    meta: {
      title: 'View Product',
      description: 'View product.',
      access: {
        guest: true,
        customer: true,
        staff: true
      }
    }
  },

  // Staff - Accounts

  {
    path: '/staff/transactions/add',
    name: 'add-transaction',
    component: AddTransactionView,
    meta: {
      title: 'Add Transaction',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/accounts',
    name: 'list-transactions',
    component: ListTransactionsView,
    meta: {
      title: 'Accounts',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Categories
  {
    path: '/staff/categories/add',
    name: 'add-category',
    component: AddCategoryView,
    meta: {
      title: 'Add Category',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/categories',
    name: 'list-categories',
    component: ListCategoriesView,
    meta: {
      title: 'Categories',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/categories/:id/view',
    name: 'view-category',
    component: ViewCategoryView,
    meta: {
      title: 'View Category',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Colours
  {
    path: '/staff/colours/add',
    name: 'add-colour',
    component: AddColourView,
    meta: {
      title: 'Add Colour',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/colours',
    name: 'list-colours',
    component: ListColoursView,
    meta: {
      title: 'Colours',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/colours/:id/view',
    name: 'view-colour',
    component: ViewColourView,
    meta: {
      title: 'View Colour',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Countires
  {
    path: '/staff/countries/add',
    name: 'add-country',
    component: AddCountryView,
    meta: {
      title: 'Add Country',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/countries',
    name: 'list-countries',
    component: ListCountriesView,
    meta: {
      title: 'Countries',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/countries/:id/view',
    name: 'view-country',
    component: ViewCountryView,
    meta: {
      title: 'View Country',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Coupons
  {
    path: '/staff/coupons/add',
    name: 'add-coupon',
    component: AddCouponView,
    meta: {
      title: 'Add Coupon',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/coupons',
    name: 'staff-list-coupons',
    component: StaffListCouponsView,
    meta: {
      title: 'Coupons',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/coupons/:id/view',
    name: 'view-coupon',
    component: ViewCouponView,
    meta: {
      title: 'View Coupon',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Delivery Zones
  {
    path: '/staff/delivery-zones/add',
    name: 'add-delivery-zone',
    component: AddDeliveryZoneView,
    meta: {
      title: 'Add Delivery Zone',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/delivery-zones',
    name: 'list-delivery-zones',
    component: ListDeliveryZonesView,
    meta: {
      title: 'Delivery Zones',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/delivery-zones/:id/view',
    name: 'view-delivery-zone',
    component: ViewDeliveryZoneView,
    meta: {
      title: 'View Delivery Zone',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Orders
  {
    path: '/staff/orders/add',
    name: 'add-order',
    component: AddOrderView,
    meta: {
      title: 'Add Order',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/orders',
    name: 'staff-list-orders',
    component: StaffListOrdersView,
    meta: {
      title: 'Orders',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/orders/:id/view',
    name: 'staff-view-order',
    component: StaffViewOrderView,
    meta: {
      title: 'View Order',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Products
  {
    path: '/staff/products/add',
    name: 'add-product',
    component: AddProductView,
    meta: {
      title: 'Add Product',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/products',
    name: 'list-products',
    component: ListProductsView,
    meta: {
      title: 'Products',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/products/:id/view',
    name: 'staff-view-product',
    component: ViewProductView,
    meta: {
      title: 'View Product',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Refunds
  {
    path: '/staff/refunds',
    name: 'staff-list-refunds',
    component: StaffListRefundsView,
    meta: {
      title: 'Refunds',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/refunds/:id/view',
    name: 'staff-view-refund',
    component: StaffViewRefundView,
    meta: {
      title: 'View Refund',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Regions
  {
    path: '/staff/regions/add',
    name: 'add-region',
    component: AddRegionView,
    meta: {
      title: 'Add Region',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/regions',
    name: 'list-regions',
    component: ListRegionsView,
    meta: {
      title: 'Regions',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/regions/:id/view',
    name: 'view-region',
    component: ViewRegionView,
    meta: {
      title: 'View Region',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Settings
  {
    path: '/staff/settings/add',
    name: 'add-setting',
    component: AddSettingView,
    meta: {
      title: 'Add Setting',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/settings',
    name: 'list-settings',
    component: ListSettingsView,
    meta: {
      title: 'Settings',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/settings/:id/view',
    name: 'view-setting',
    component: ViewSettingView,
    meta: {
      title: 'View Setting',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Sizes
  {
    path: '/staff/sizes/add',
    name: 'add-size',
    component: AddSizeView,
    meta: {
      title: 'Add Size',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/sizes',
    name: 'list-sizes',
    component: ListSizesView,
    meta: {
      title: 'Sizes',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/sizes/:id/view',
    name: 'view-size',
    component: ViewSizeView,
    meta: {
      title: 'View Size',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },

  // Staff - Users
  {
    path: '/staff/users/add',
    name: 'add-user',
    component: AddUserView,
    meta: {
      title: 'Add User',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/users',
    name: 'list-users',
    component: ListUsersView,
    meta: {
      title: 'Users',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
  {
    path: '/staff/users/:id/view',
    name: 'view-user',
    component: ViewUserView,
    meta: {
      title: 'View User',
      access: {
        guest: false,
        customer: false,
        staff: true
      }
    }
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = 'The Jeans Shop GH - ' + to.meta.title || DEFAULT_TITLE;
  });
});

export default router
