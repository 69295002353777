<template>
  <div class="row justify-content-center p-4 pt-5">
    <div class="col-md-4 text-right">
      <h1 class=" mb-3">
        <page-header
          :title="`View Order No. ${$route.params.id}`"
          :return_button="true"
          return_link="/account-area/orders"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
      </h1>
      <loader v-if="loading.page"></loader>
      <div v-else class="row">
        <div class="col-md-12" style="font-size: 0.9rem">
          <div>
            Type: <b>{{ order.type_code }}</b>
          </div>
          <div>
            Customer:
            <b>{{ order.customer ? order.customer.email : "Guest" }}</b>
          </div>
          <div>
            Teller: <b>{{ order.teller ? order.teller.email : "N/a" }}</b>
          </div>
          <div>
            Status: <b>{{ order.status_code }}</b>
          </div>
          <div class="mt-3">
            <div
              class="row"
              v-for="(item, index) in order.items"
              :key="item.id"
            >
              <div class="col-2 mb-3" style="padding-left: 0">
                <img
                  :src="
                    item.product_variation.product.images[0].file.storage_link
                  "
                  width="100%"
                  class="lazyload"
                />
                <span
                  class="badge badge-dark fw-bolder"
                  style="
                    background-color: black;
                    position: absolute;
                    margin-left: -10px;
                    margin-top: -10px;
                    font-size: 0.7rem;
                    border-radius: 15px;
                  "
                  >{{ item.quantity }}</span
                >
              </div>
              <div
                class="col-10 p-2 pt-1"
                style="padding-left: 15px !important"
              >
                <div class="row">
                  <div class="col-md-12 p-0 spread-cols-to-ends">
                    <div>
                      <span class="fw-bolder"
                        >{{ item.product_variation.product.name }}
                      </span>
                      <div>
                        <span style="">
                          <span class="fw-normal mt-2" style="font-size: 0.9rem"
                            >{{ item.product_variation.size.name }}
                          </span>
                          <div
                            v-if="item.product_variation.colour_code"
                            class="box-shadow-light"
                            :style="`width: 30px; height: 18px; background-color: #${item.product_variation.colour_code}; margin-top: 5px`"
                          ></div>
                        </span>
                      </div>
                    </div>
                    <div class="">
                      <div class="fw-bold text-end">
                        <span class="currency_code">GH₵ </span
                        >{{
                          item.product_variation.product.retail_price *
                          item.quantity
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr v-if="index != order.items.length - 1" />
            </div>
          </div>

          <div class="mt-3" style="font-size: 0.9rem">
            <div class="spread-cols-to-ends" style="font-weight: bold">
              <div>Subtotal:</div>
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(order.subtotal) }}
              </div>
            </div>
            <div class="spread-cols-to-ends" style="font-weight: 500">
              <div>Delivery Fee:</div>
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(order.shipping_fee) }}
              </div>
            </div>
            <div class="spread-cols-to-ends" style="font-weight: 500">
              <div>Discount:</div>
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(order.discount) }}
              </div>
            </div>
            <hr class="mt-1 mb-1" />
            <div
              class="text-end"
              style="font-weight: bolder; font-size: 1.3rem"
            >
              <div>
                <span class="currency_code" style="font-size: 0.8rem"
                  >GH₵
                </span>
                {{ formatAmount(order.total) }}
              </div>
            </div>
          </div>
          <div v-if="order.delivery_method_code == 'delivery'" class="mt-3">
            <b>Delivery Information</b>
            <div style="font-size: 0.8rem" class="mt-2">
              <div>
                Country: <b>{{ order.shipping_country_code }}</b>
              </div>
              <div>
                Region: <b>{{ order.shipping_region_code }}</b>
              </div>
              <div>
                Zone: <b>{{ order.shipping_delivery_zone_code }}</b>
              </div>
              <div class="mt-1">
                Address:
                <b>
                  <div>
                    {{ order.shipping_address.first_name }}
                    {{ order.shipping_address.last_name }}
                  </div>
                  <div>{{ order.shipping_address.email }}</div>
                  <div>{{ order.shipping_address.phone }}</div>
                  <div>{{ order.shipping_address.address }}</div>
                </b>
              </div>
              <div class="mt-2">
                Notes:
                <b>{{ order.shipping_additional_details ?? "N/a." }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import Loader from "@/components/Loader.vue";
import OrderService from "@/services/customer/OrderService";
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
export default {
  name: "ViewOrderView",
  components: { Loader, PageHeader },
  mounted() {
    OrderService.getOrder(this.$route.params.id).then((response) => {
      const order = response.data.order;
      this.order = order;
      this.loading.page = false;
    });
  },
  data() {
    return {
      loading: {
        page: true,
        order: false,
      },
    };
  },
  methods: {
    formatAmount(number) {
      return new Intl.NumberFormat("en-GH", {}).format(number);
    },
  },
};
</script>