<template>
  <div>
    <loader v-if="loading.page"> </loader>
    <div v-else>
      <div class="row justify-content-center mt-5 m-mt-0">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-2 p-2 show-desktop-only">
              <div
                style="
                  font-weight: bolder;
                  text-transform: uppercase;
                  font-size: 0.8rem;
                  letter-spacing: 0.12rem;
                "
                class="mb-2"
              >
                Filter By
              </div>
              <div class="spread-cols-to-ends mt-3">
                <div
                  style="
                    font-weight: bold;
                    font-size: 0.8rem;
                    letter-spacing: 0.02rem;
                  "
                >
                  Categories
                </div>
                <div></div>
              </div>
              <div class="mt-1">
                <div
                  v-for="category in categories"
                  :key="category.id"
                  class="form-check form-check-inline col-md-11"
                  style="letter-spacing: 0.05rem"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :true-value="[]"
                    v-model="filter.categories"
                    :value="category.id"
                    :id="`categoryCheck${category.id}`"
                  />
                  <label
                    class="form-check-label"
                    :for="`categoryCheck${category.id}`"
                  >
                    <div
                      style="
                        font-size: 0.8rem;
                        font-weight: 600;
                        letter-spacing: 0rem;
                      "
                    >
                      {{ category.name }}
                    </div>
                  </label>
                </div>
              </div>
              <div class="spread-cols-to-ends mb-1 mt-3">
                <div
                  style="
                    font-weight: bold;
                    font-size: 0.8rem;
                    letter-spacing: 0.02rem;
                  "
                >
                  Sizes
                </div>
                <div></div>
              </div>
              <div>
                <div
                  v-for="size in sizes"
                  :key="size.code"
                  class="form-check form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="filter.sizes"
                    :value="size.code"
                    :id="`sizeCheck${size.code}`"
                  />
                  <label
                    class="form-check-label"
                    :for="`sizeCheck${size.code}`"
                  >
                    <div style="font-size: 0.8rem; font-weight: 600">
                      {{ size.name }}
                    </div>
                  </label>
                </div>
              </div>
              <div class="spread-cols-to-ends mb-1 mt-4">
                <div
                  style="
                    font-weight: bold;
                    font-size: 0.8rem;
                    letter-spacing: 0.02rem;
                  "
                >
                  Colours
                </div>
                <div></div>
              </div>
              <div>
                <div
                  v-for="colour in colours"
                  :key="colour.code"
                  class="form-check form-check-inline mb-1 mt-1"
                  style="font-size: 0.8rem"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :true-value="[]"
                    v-model="filter.colours"
                    :value="colour.code"
                    :id="`colourCheck${colour.code}`"
                  />
                  <label
                    class="form-check-label"
                    :for="`colourCheck${colour.code}`"
                  >
                    <div
                      class="box-shadow-light mt-1"
                      :style="`width: 30px; height: 18px; background-color: #${colour.code};`"
                    ></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-10 p-2">
              <div class="spread-cols-to-ends show-mobile-only">
                <div
                  class="input-group mb-3 col-12 w-100 p-2"
                  style="border-radius: 0px; width: 350px; height: 38px"
                >
                  <input
                    v-model="filter.search"
                    type="text"
                    class="form-control"
                    placeholder="Search Our Store."
                    aria-describedby="search-button"
                  />
                  <button class="btn btn-dark" type="button" id="search-button">
                    <i class="bi bi-search"></i>
                  </button>
                </div>
                <div
                  class="col-12 p-2 pt-0"
                  style="display: flex; justify-content: space-between"
                >
                  <div style="margin-right: 5px">
                    <input
                      v-model="filter.sort"
                      value="newness"
                      type="radio"
                      class="btn-check"
                      id="newness"
                      autocomplete="off"
                      checked
                    />
                    <label
                      class="btn mt-1 btn-outline-dark btn-sm fw-bold mobile-sort-label"
                      for="newness"
                      >Latest</label
                    >
                  </div>
                  <div style="margin-right: 5px">
                    <input
                      v-model="filter.sort"
                      value="popularity"
                      type="radio"
                      class="btn-check"
                      id="popularity"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark btn-sm fw-bold mobile-sort-label"
                      for="popularity"
                      >Popular</label
                    >
                  </div>
                  <!-- <div style="margin-right: 5px">
                    <input
                     v-model="filter.sort"
                      value="price-high-to-low"
                      type="radio"
                      class="btn-check"
                      id="price-high-to-low"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark btn-sm fw-bold"
                      for="price-high-to-low"
                      >High Price</label
                    >
                  </div> -->
                  <div style="margin-right: 5px">
                    <input
                      v-model="filter.sort"
                      value="price-low-to-high"
                      type="radio"
                      class="btn-check"
                      id="price-low-to-high"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark btn-sm fw-bold mobile-sort-label"
                      for="price-low-to-high"
                      >Low Price</label
                    >
                  </div>
                  <div style="margin-right: 5px">
                    <input
                      v-model="filter.sort"
                      value="name-a-to-z"
                      type="radio"
                      class="btn-check"
                      id="name-a-to-z"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark btn-sm fw-bold mobile-sort-label"
                      for="name-a-to-z"
                      >A to Z</label
                    >
                  </div>
                  <div style="margin-right: 5px">
                    <input
                      v-model="filter.sort"
                      value="name-z-to-a"
                      type="radio"
                      class="btn-check"
                      id="name-z-to-a"
                      autocomplete="off"
                    />
                    <label
                      class="btn mt-1 btn-outline-dark btn-sm fw-bold mobile-sort-label"
                      for="name-z-to-a"
                      >Z to A</label
                    >
                  </div>
                </div>
              </div>
              <div class="spread-cols-to-ends show-desktop-only">
                <div>
                  <select
                    class="form-select"
                    style="border-radius: 0px; width: 250px"
                    v-model="filter.sort"
                  >
                    <option value="newness" selected>
                      Sort by - Latest Products
                    </option>
                    <option value="popularity">Sort by - Popularity</option>
                    <option value="price-high-to-low">
                      Sort by - Price, High to Low
                    </option>
                    <option value="price-low-to-high">
                      Sort by - Price, Low to High
                    </option>
                    <option value="name-a-to-z">Sort by - Name, A to Z</option>
                    <option value="name-z-to-a">Sort by - Name, Z to A</option>
                  </select>
                </div>

                <div
                  class="input-group mb-3"
                  style="border-radius: 0px; width: 350px; height: 38px"
                >
                  <input
                    v-model="filter.search"
                    type="text"
                    class="form-control"
                    placeholder="Search Our Store."
                    aria-describedby="search-button"
                  />
                  <button class="btn btn-dark" type="button" id="search-button">
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </div>
              <div>
                <div class="row">
                  <loader v-if="loading.records"></loader>
                  <div
                    v-else
                    class="col-lg-3 col-6 p-2"
                    v-for="product in products"
                    :key="`product-${product.id}`"
                  >
                    <product-grid-item v-on="$listeners" :product="product"></product-grid-item>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button
                    v-if="
                      this.products.length < pagination.totalRows &&
                      !loading.records
                    "
                    @click="loadMore"
                    type="button"
                    class="btn btn-dark btn-sm mt-1 box-shadow-light"
                  >
                    {{ loading.records ? "Loading ..." : "Load more" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import Loader from "@/components/Loader.vue";
import ShopService from "@/services/customer/ShopService";
import ProductGridItem from "@/components/shop/ProductGridItem.vue";

export default {
  name: "ShopView",
  components: {
    Loader,
    ProductGridItem,
  },
  data() {
    return {
      loading: {
        page: true,
        records: false,
      },
      filter: {
        search: "",
        categories: [],
        colours: [],
        sizes: [],
        sort: "newness",
      },
      categories: [],
      colours: [],
      sizes: [],
      products: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },
  async mounted() {
    ShopService.getCategories().then((response) => {
      this.categories = response.data.categories.data;
    });

    ShopService.getColours(1, 100).then((response) => {
      this.colours = [
        ...response.data.colours.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    ShopService.getSizes(1, 100).then((response) => {
      this.sizes = [
        ...response.data.sizes.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    if (this.$route.query.category != null) {
      this.filter.categories = this.$route.query.category.split(",");
    }

    console.log(this.$route);
    if (this.$route.query.search != null) {
      this.filter.search = this.$route.query.search
    } else {
      this.getProducts(true);
    }
    

    this.loading.page = false;
  },
  methods: {
    async getProducts(search, loadMore = false) {
      if (!loadMore) {
        this.loading.records = true;
      }
      
      if (search === true) {
        this.pagination.currentPage = 1;
        this.products = [];
      }

      // Process sort
      let sort_column = "";
      let sort_direction = "";

      switch (this.filter.sort) {
        case "popularity":
          sort_column = "views";
          sort_direction = "desc";
          break;
        case "price-high-to-low":
          sort_column = "products.retail_price";
          sort_direction = "desc";
          break;
        case "price-low-to-high":
          sort_column = "products.retail_price";
          sort_direction = "asc";
          break;
        case "name-a-to-z":
          sort_column = "products.name";
          sort_direction = "asc";
          break;
        case "name-z-to-a":
          sort_column = "products.name";
          sort_direction = "desc";
          break;

        default:
          sort_column = "products.created_at";
          sort_direction = "desc";
          break;
      }

      let response = await ShopService.getProducts(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.filter.search,
        this.filter.categories,
        this.filter.sizes,
        this.filter.colours,
        sort_column,
        sort_direction
      );

      if (search === true) {
        this.products = [];
      }
      this.products = this.products.concat(response.data.products.data);
      this.pagination.totalRows = response.data.products.total;
      if (!loadMore) {
        this.loading.records = false;
      }
      this.pagination.totalPages = response.data.products.last_page;
    },

    loadMore() {
      this.pagination.currentPage = this.pagination.currentPage + 1;
      if (this.pagination.currentPage <= this.pagination.totalPages) {
        this.getProducts(false, true);
      }
    },
  },
  watch: {
    filter: {
      handler() {
        this.getProducts(true);
      },
      deep: true,
    },
  },
};
</script>