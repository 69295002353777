<template>
  <div class="row justify-content-center p-4 pt-5">
    <div class="col-md-4 text-right">
      <h1 class="playfair-display mb-3">Your Orders</h1>
      <loader v-if="loading.page"></loader>
      <div v-else class="row">
        <div v-for="order in orders" :key="order.id" class="col-md-12 mb-2">
          <div class="spread-cols-to-ends">
            <div>
              <div class="playfair-display" style="font-size: 1.1rem">
                <b>#{{ order.id }}</b> <br>
                {{ moment(order.created_at).format("MMMM Do YYYY, h:mm a") }}
              </div>
              <div style="font-size: 0.9rem">
                <b><span class="currency_code">GH₵</span> {{ order.total }}</b>
                - {{ order.status.name }}
              </div>
            </div>
            <div >
              <a :href="`/account-area/orders/${order.id}/view`">
                <button class="btn btn-dark mt-4" type="button" id="search-button">
                  View
                </button>
              </a>
            </div>
          </div>
        </div>
        <nav>
          <ul class="pagination justify-content-center mt-2 mb-5">
            <li v-if="pagination.currentPage > 1" class="page-item">
              <a
                class="page-link"
                href="#"
                aria-label="Previous"
                @click.prevent="changePage(pagination.currentPage - 1)"
              >
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li
              class="page-item"
              v-for="page in pagination.totalPages"
              :key="page"
              :class="{ active: page == pagination.currentPage }"
            >
              <a
                v-if="
                  page < 3 ||
                  page > pagination.totalPages - 3 ||
                  (page < pagination.currentPage + 3 &&
                    page > pagination.currentPage - 3)
                "
                class="page-link"
                @click.prevent="changePage(page)"
                >{{ page }}</a
              >
            </li>
            <li
              v-if="pagination.currentPage < pagination.totalPages"
              class="page-item"
            >
              <a
                class="page-link"
                aria-label="Next"
                @click.prevent="changePage(pagination.currentPage + 1)"
              >
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import OrderService from "@/services/customer/OrderService";
import Loader from "@/components/Loader.vue";

export default {
  name: "ListOrdersView",
  components: { Loader },
  data() {
    return {
      loading: {
        page: false,
      },
      orders: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },

  async mounted() {
    await this.getRecords();
    this.loading.page = false;
  },

  methods: {
    async getRecords(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await OrderService.getOrders(
        this.pagination.currentPage,
        this.pagination.perPage
      );

      this.orders = response.data.orders.data;
      this.pagination.totalRows = response.data.orders.total;
      this.loading.page = false;
      this.pagination.totalPages = response.data.orders.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getRecords();
      }
    },
  },
};
</script>