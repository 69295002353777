<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <page-header
          title="View Refund"
          :return_button="true"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
export default {
  name: "ViewRefundView",
  components: {
    PageHeader,
  },
};
</script>
