<template>
  <div :class="center ? 'custom-loader-center' : 'custom-loader'" style="margin-bottom: 100px;">
    <div class="spinner-border mt-10" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomLoader",
  props: ["center"],
};
</script>

<style scoped>
.custom-loader {
  text-align: center !important;
}

.custom-loader-center {
  z-index: 9;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>