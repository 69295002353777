<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="New User"
          :return_button="true"
          return_link="/staff/users"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="name" class="form-label">Name</label>
              <input
                type="name"
                class="form-control"
                id="name"
                placeholder="Enter user name."
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="email" class="form-label">Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Enter user email."
                v-model="form.email"
                :disabled="loading.form"
              />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="role_code" class="form-label">Role</label>
                  <select
                    v-model="form.role_code"
                    id="role_code"
                    class="form-select form-select-sm"
                    :disabled="loading.form"
                  >
                    <option value="MANAGER">Manager</option>
                    <option value="CUSTOMER">Customer</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="password" class="form-label">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="Create a password."
                    v-model="form.password"
                    :disabled="loading.form"
                  />
                </div>
              </div>
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Add User" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import UserService from "@/services/staff/UserService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddUserView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        name: "",
        email: "",
        password: "",
        role_code: "MANAGER",
      },
      success: "",
      errors: [],
    };
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      UserService.addUser(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.form.name = "";
          this.form.email = "";
          this.form.password = "";
          this.form.role_code = "MANAGER";
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
