<template>
  <div class="row justify-content-center mt-10 mb-5">
    <div class="col-md-4 text-center">
      <h1 class="playfair-display">Page not found.</h1>
      <p>Oops. We can't seem to find this.</p>
    </div>

  </div>
</template>
  
<script>
export default {
  name: "NotFoundView",
  components: {},
};
</script>