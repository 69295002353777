<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="New Region"
          :return_button="true"
          return_link="/staff/regions"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="country_code" class="form-label">Country</label>
              <select
                v-model="form.country_code"
                id="country_code"
                class="form-select form-select-sm"
                :disabled="loading.form"
              >
                <option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.code"
                >
                  {{ country.description }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="code" class="form-label">Region Code</label>
              <input
                type="text"
                class="form-control"
                id="code"
                placeholder="Enter ISO2 Region Code. E.g GH-AA"
                v-model="form.code"
                :disabled="true"
              />
            </div>
            <div class="form-group">
              <label for="name" class="form-label">Region Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter Region Name. E.g Greater Accra"
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Update Region" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import CountryService from "@/services/staff/CountryService";
import RegionService from "@/services/staff/RegionService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddRegionView",
  components: {
    PageHeader,
  },
  mounted() {
    CountryService.getCountries().then((response) => {
      this.countries = [
        this.countries[0],
        ...response.data.countries.data.map((item) => {
          return {
            code: `${item.code}`,
            description: `${item.name}`,
          };
        }),
      ];
    });

    RegionService.getRegion(this.$route.params.id).then((response) => {
      const region = response.data.region;
      this.form.country_code = region.country_code;
      this.form.code = region.code;
      this.form.name = region.name;
    });
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        country_code: "",
        code: "",
        name: "",
      },
      success: "",
      errors: [],
      countries: [{ description: "Kindly select a country.", code: "" }],
    };
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      RegionService.updateRegion(this.$route.params.id, this.form)
        .then((response) => {
          this.success = response.data.message;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
