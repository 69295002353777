import { APIService } from './../authentication/APIService'

export default {
    getSizes(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/sizes?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getSize(sizeId) {
        return APIService.get("api/staff/sizes/" + sizeId);
    },

    addSize(payload) {
        return APIService.post("api/staff/sizes", payload);
    },

    updateSize(sizeId, payload) {
        return APIService.patch("api/staff/sizes/" + sizeId, payload);
    },
}