import { render, staticRenderFns } from "./CenterMessage.vue?vue&type=template&id=3721bc90&scoped=true"
import script from "./CenterMessage.vue?vue&type=script&lang=js"
export * from "./CenterMessage.vue?vue&type=script&lang=js"
import style0 from "./CenterMessage.vue?vue&type=style&index=0&id=3721bc90&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3721bc90",
  null
  
)

export default component.exports