var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container mt-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('page-header',{attrs:{"title":"Users","return_button":false,"action_button":true,"action_button_text":"New User","action_button_link":"/staff/users/add"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(!_vm.loading.page && _vm.users.length > 0)?_c('div',[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(user.id))]),_c('td',[_vm._v(_vm._s(user.name))]),_c('td',{staticClass:"fw-bolder"},[_vm._v(_vm._s(user.email))]),_c('td',[_vm._v(_vm._s(user.role.name))]),_c('td',{staticClass:"text-end"},[_c('a',{attrs:{"href":`/staff/users/${user.id}/view`}},[_c('i',{staticClass:"bi-pencil-square fs-5 text-primary me-2"})])])])}),0)]),_c('nav',[_c('ul',{staticClass:"pagination justify-content-center mt-5"},[(_vm.pagination.currentPage > 1)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"href":"#","aria-label":"Previous"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage - 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]):_vm._e(),_vm._l((_vm.pagination.totalPages),function(page){return _c('li',{key:page,staticClass:"page-item",class:{ active: page == _vm.pagination.currentPage }},[(
                      page < 3 ||
                      page > _vm.pagination.totalPages - 3 ||
                      (page < _vm.pagination.currentPage + 3 &&
                        page > _vm.pagination.currentPage - 3)
                    )?_c('a',{staticClass:"page-link",on:{"click":function($event){$event.preventDefault();return _vm.changePage(page)}}},[_vm._v(_vm._s(page))]):_vm._e()])}),(_vm.pagination.currentPage < _vm.pagination.totalPages)?_c('li',{staticClass:"page-item"},[_c('a',{staticClass:"page-link",attrs:{"aria-label":"Next"},on:{"click":function($event){$event.preventDefault();return _vm.changePage(_vm.pagination.currentPage + 1)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]):_vm._e()],2)])]):_vm._e(),(_vm.loading.page)?_c('loader'):_vm._e()],1)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Email")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Role")]),_c('th',{staticClass:"text-end",attrs:{"scope":"col"}},[_vm._v("Action")])])])
}]

export { render, staticRenderFns }