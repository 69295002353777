<template>
  <div class="row mb-2">
    <div :class="action_button || return_button ? 'col-md-8' : 'col-md-12'">
      <h1 :class="'playfair-display ' + (center_title ? 'text-center' : '')">
        {{ title }}
      </h1>
    </div>
    <div v-if="action_button || return_button" class="col-md-4 text-end">
      <button
        @click="return_link ? $router.push(return_link) : $router.back()"
        v-if="return_button"
        type="button"
        class="btn btn-sm pe-3 mt-1 box-shadow-light"
      >
        <i class="bi bi-chevron-left"></i> Go Back
      </button>
      <a v-if="action_button" :href="action_button_link" style="text-decoration: none;">
        &nbsp;
        <button
          v-if="action_button"
          type="button"
          class="btn btn-primary btn-sm mt-1 box-shadow-light"
        >
          {{ action_button_text }}
        </button></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: [
    "title",
    "center_title",
    "return_button",
    "return_link",
    "action_button",
    "action_button_text",
    "action_button_link",
  ],
};
</script>