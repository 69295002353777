import { CaretakerApiService } from '../authentication/CaretakerAPIService';
import { APIService } from './../authentication/APIService';
import store from '@/store';

export default {
    getCountries() {
        return CaretakerApiService.get("api/checkout/countries");
    },

    getRegions(country = '') {
        // Generate url
        let url = "api/checkout/regions?";

        if (country) {
            url += "&country_code=" + country;
        }

        return CaretakerApiService.get(url);
    },

    getDeliveryZones(region = '') {
        // Generate url
        let url = "api/checkout/delivery/zones?";

        if (region) {
            url += "&region_code=" + region;
        }

        return CaretakerApiService.get(url);
    },

    validateCoupon(payload) {
        return CaretakerApiService.post("api/checkout/coupons/validate", payload);
    },

    submitOrder(payload) {
        if (store.getters['user/object']) {
            return APIService.post("api/orders", payload);
        } else {
            return CaretakerApiService.post("api/orders", payload);
        }
    },

    validateOrderPayment(payload) {
        if (store.getters['user/object']) {
            return APIService.patch("api/orders", payload);
        } else {
            return CaretakerApiService.patch("api/orders", payload);
        }
    },
}