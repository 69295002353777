import { APIService } from './../authentication/APIService'

export default {
    getRegions(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/regions?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getRegion(regionId) {
        return APIService.get("api/staff/regions/" + regionId);
    },

    addRegion(payload) {
        return APIService.post("api/staff/regions", payload);
    },

    updateRegion(regionId, payload) {
        return APIService.patch("api/staff/regions/" + regionId, payload);
    },
}