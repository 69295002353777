import { APIService } from './../authentication/APIService'

export default {
    getSettings(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/settings?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getSetting(settingId) {
        return APIService.get("api/staff/settings/" + settingId);
    },

    addSetting(payload) {
        return APIService.post("api/staff/settings", payload);
    },

    updateSetting(settingId, payload) {
        return APIService.patch("api/staff/settings/" + settingId, payload);
    },
}