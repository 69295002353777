import store from "@/store";
import AuthenticationService from "./AuthenticationService";

export default {
    async getAccessToken(type) {
        let token;
        switch (type) {
            case 'user':
                token = store.getters["user/token"];
                break;
            case 'caretaker':
                token = store.getters["caretaker/token"];

                if (token) {
                    return token;
                }

                // Generate caretaker token
                token = await AuthenticationService.login({
                    email: process.env.VUE_APP_CARETAKER_EMAIL,
                    password: process.env.VUE_APP_CARETAKER_PASSWORD,
                    device_name: navigator.userAgent,
                })

                token = token.data.token;

                // Set caretaker token
                store.commit('caretaker/SET_TOKEN', token);

                break;
        }

        return token
    }
}