<template>
  <div>
    <loader v-if="loading.page"> </loader>
    <div v-else>
      <ul class="nav justify-content-center bg-dark">
        <li class="nav-item">
          <span class="nav-link fc-white fw-bold" style="letter-spacing: 0.2rem;">{{ announcements[announcementIndex]
            }}</span>
        </li>
      </ul>
      <div class="row horizontal-banner-mobile mobile-home-main-banner show-mobile-only">
        <div class="pt-5 col-md-6 text-center">
          <div class="playfair-display h2 fc-white mb-2 mt-10" style="font-style: italic; font-size: 3rem">
            New Arrivals
          </div>
        </div>
        <div class="col-md-6 mobile-about-first-paragraph">
          <div class="row justify-content-center">
            <div class="col-md-7">
              <a href="/shop" style="
                  text-decoration: none;
                  text-decoration: underline;
                  text-decoration-color: white;
                ">
                <h5 class="fc-white text-center fw-bolder" style="text-transform: uppercase">
                  Shop New Arrivals <i class="bi bi-arrow-right"></i>
                </h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row show-desktop-only">
        <div id="homeCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
          <div v-lazy-container="{ selector: 'img' }" class="carousel-inner">
            <div class="carousel-item active">
              <img data-src="/home/models-happy-in-jeans-apparel-christmas.png"
                data-loading="/banner/main-banner-placeholder.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img data-src="/home/models-happy-in-jeans-apparel.jpg" class="d-block w-100"
                data-loading="/banner/main-banner-placeholder.jpg" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container show-desktop-only">
        <div class="row mt-5 justify-content-center">
          <div class="col-md-4">
            <h1 class="playfair-display" style="font-size: 2.5rem">
              New Arrivals
            </h1>
            <div v-if="categories" class="fw-bold mt-4 mb-4" style="font-size: 0.9rem">
              <div v-for="category in categories" :key="category.id" class="row">
                <div class="col-md-12">
                  <a class="new-arrivals-category" :href="`/shop?category=${category.id}`" style="color: black">{{
                    category.name }}</a>
                </div>
              </div>
            </div>
            <a href="/shop">
              <button type="button" class="btn btn-dark">
                <b style="font-weight: bolder">Browse Our Store Now</b>
              </button>
            </a>
          </div>
          <div class="col-md-8">
            <div class="row mb-5">
              <div class="col-md-4 p-3" v-for="product in products.new_arrivals" :key="product.id">
                <product-grid-item v-on="$listeners" :product="product"></product-grid-item>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row show-mobile-only mt-3 mb-1">
        <div class="col-md-12">
          <h1 class="playfair-display text-center" style="font-size: 2rem">Collections</h1>
        </div>
      </div>
      <div class="show-mobile-only">
        <div class="row mx-4 mb-3">
          <div class="col-6 p-2 fw-bold" v-for="category in categories" :key="category.id">
            <a :href="`/shop?category=${category.id}`" style="text-decoration: none; color: black; font-weight: bolder">
              {{ category.name }}
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 show-mobile-only p-0" v-lazy-container="{ selector: 'img' }" style="height: 300px">
          <img data-src="/home/model-seated-in-trunk.png" class="d-block w-100"
            data-loading="/banner/mini-banner-placeholder.jpg" alt="..."
            style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
        </div>
        <div class="col-md-6 p-5 fc-white bg-dark">
          <div class="row justify-content-center">
            <div class="col-md-7">
              <h1 class="playfair-display mt-5" style="font-size: 2.5rem">
                Introducing
              </h1>
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Cargo Pants
              </h1>
              <div class="mb-3">
                Cargo pants are versatile, loose-fitting trousers designed with multiple pockets, originally intended
                for utility and practicality.
              </div>
              <div class="mb-3">
                First introduced as military wear in the 1930s, cargo pants have since evolved into a popular fashion
                staple, blending rugged functionality with casual style.
              </div>
              <a href="/shop?category=4">
                <button type="button" class="btn btn-light mb-5">
                  <b style="font-weight: bolder">SHOP NOW</b>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 show-desktop-only p-0" v-lazy-container="{ selector: 'img' }">
          <img data-src="/home/model-seated-in-trunk.png" class="d-block w-100"
            data-loading="/banner/mini-banner-placeholder.jpg" alt="..."
            style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-md-12 text-center">
          <h5>NEW SEASON, NEW STYLES</h5>
          <h1 class="playfair-display" style="font-size: 2.5rem">Explore</h1>
          <div class="row mt-3 justify-content-center text-start">
            <div class="col-md-9">
              <div class="row">
                <div class="col-lg-3 col-6 p-2" v-for="product in products.explore" :key="product.id">
                  <product-grid-item v-on="$listeners" :product="product"></product-grid-item>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="/shop">
                  <button type="button" class="btn btn-dark">
                    <b style="font-weight: bolder">Browse Our Store Now</b>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row show-desktop-only">
        <div class="col-md-6 mt-5 p-0" style="min-height: 500px;" v-lazy-container="{ selector: 'img' }">
          <img data-src="/home/models-in-pink-tops-and-grey-jeans.png" class="d-block w-100"
            data-loading="/banner/mini-banner-placeholder.jpg" alt="..."
            style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
        </div>
        <div class="col-md-6">
          <div class="row" style="margin-top: 100px">
            <div class="col-md-8">
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Enjoy up to 50% off <br />
                this Festive Season
              </h1>
              <div class="mb-4 mt-4" style="font-size: 1.1rem">
                <div class="mb-3">Thank you for sticking with us for all these years.</div>
                <div class="mb-3">As a way of giving back, enjoy up to 50% discount when you buy from the website.</div>
                <div>This offer lasts till the end of the campaign.</div>
              </div>
              <a href="/shop">
                <button type="button" class="btn btn-dark mb-5">
                  <b style="font-weight: bolder">Explore our Shop</b>
                </button>
              </a>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
      <div class="row show-mobile-only">
        <div class="col-md-6 mt-5 p-0" style="min-height: 500px;" v-lazy-container="{ selector: 'img' }">
          <img data-src="/home/models-high-five-in-crop-tops.png" class="d-block w-100"
            data-loading="/product/product-placeholder.jpg" alt="..."
            style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
        </div>
        <div class="col-md-6">
          <div class="row p-3 pt-0 text-center" style="margin-top: 100px">
            <div class="col-md-8">
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Enjoy up to 50% off <br />
                this Festive Season
              </h1>
              <div class="mb-4 mt-4" style="font-size: 1.1rem">
                <div class="mb-3">Thank you for sticking with us for all these years.</div>
                <div class="mb-3">As a way of giving back, enjoy up to 50% discount when you buy from the website.</div>
                <div>This offer lasts till the end of the campaign.</div>
              </div>
              <a href="/shop">
                <button type="button" class="btn btn-dark mb-5">
                  <b style="font-weight: bolder">Explore our Shop</b>
                </button>
              </a>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
      <div class="row horizontal-banner-full mb-3 mt-3 show-desktop-only p-0" v-lazy-container="{ selector: 'img' }">
        <img data-src="/home/models-in-faded-jeans-top-and-bottom.png" class="d-block w-100"
          data-loading="/banner/main-banner-placeholder.jpg" alt="..."
          style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
      </div>
      <div class="row show-desktop-only">
        <div class="col-md-6">
          <div class="row" style="margin-top: 200px">
            <div class="col-md-5"></div>
            <div class="col-md-7">
              <h1 class="playfair-display" style="font-size: 2.5rem">
                Crop Tops, Jackets <br />& T-Shirts Available
              </h1>
              <div class="mb-4 mt-4" style="font-size: 1.1rem">
                Explore our wide range of t-shirts and crop tops that you can
                use to style your jeans.
              </div>
              <a href="/shop?category=6,7">
                <button type="button" class="btn btn-dark mb-5">
                  <b style="font-weight: bolder">Shop Now</b>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-10 mt-5 p-0" style="min-height: 400px;" v-lazy-container="{ selector: 'img' }">
          <img data-src="/home/models-in-yellow-crop-tops.png" class="d-block w-100"
            data-loading="/banner/mini-banner-placeholder.jpg" alt="..."
            style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
        </div>
      </div>
      <div class="row show-mobile-only">
        <div class="col-md-6 p-0" style="min-height: 300px" v-lazy-container="{ selector: 'img' }">
          <img data-src="/home/models-in-yellow-crop-tops.png" class="d-block w-100"
            data-loading="/banner/mini-banner-placeholder.jpg" alt="..."
            style=" height: 100%; width: auto; object-fit: cover; object-position: center; background-repeat: no-repeat;">
        </div>
        <div class="col-md-6 p-4">
          <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-7">
              <h1 class="playfair-display" style="font-size: 2.2rem">
                Crop Tops, Jackets <br />& T-Shirts Available
              </h1>
              <div class="mb-4 mt-4" style="font-size: 1.1rem">
                Explore our wide range of t-shirts and crop tops that you can
                use to style your jeans.
              </div>
              <a href="/shop?category=6,7">
                <button type="button" class="btn btn-dark mb-5">
                  <b style="font-weight: bolder">Shop Now</b>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row bg-grey p-5">
        <div class="col-md-7 mt-2 mb-5 justify-content-center">
          <h1 class="playfair-display text-center" style="font-size: 2.5rem">
            FAQs
          </h1>
        </div>
        <div class="col-md-5"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import ShopService from "@/services/customer/ShopService";
import Loader from "@/components/Loader.vue";
import ProductGridItem from "@/components/shop/ProductGridItem.vue";

export default {
  name: "HomeView",
  components: {
    Loader,
    ProductGridItem,
  },
  data() {
    return {
      loading: {
        page: true,
      },
      announcements: [
        'HAPPY HOLIDAYS!',
        'UP TO 50% DISCOUNTS ON PRODUCTS',
        'FREE DELIVERY FOR ORDERS ABOVE GHS 2000',
        'DELIVERY IS BETWEEN 5TH TO 21ST DECEMBER'
      ],
      announcementIndex: 0,
      categories: [],
      products: {
        explore: [],
        new_arrivals: [],
      },
    };
  },
  mounted() {
    this.$emit("refreshCartCount");
    this.$emit("refreshWishlistCount");

    ShopService.getCategories().then((response) => {
      this.categories = response.data.categories.data;
    });

    // Get Explore Products
    ShopService.getProducts(
      1,
      4,
      "",
      [],
      [],
      [],
      "products.views",
      "desc"
    ).then((response) => {
      this.products.explore = response.data.products.data;
    });

    // Get New Arrivals
    ShopService.getProducts(
      1,
      3,
      "",
      [],
      [],
      [],
      "products.created_at",
      "desc"
    ).then((response) => {
      this.products.new_arrivals = response.data.products.data;
    });

    this.changeAnnouncement();

    this.loading.page = false;
  },
  methods: {
    changeAnnouncement() {
      setInterval(this.initRotation, 5000)
    },
    initRotation() {
      // Update the current word index
      this.announcementIndex = (this.announcementIndex + 1) % this.announcements.length;
    }
  },

  beforeDestroy() {
    // Remove the interval runner when the component is destroyed!
    clearInterval(this.initRotation)
  }
};
</script>
