<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="View Category"
          :return_button="true"
          return_link="/staff/categories"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="region_code" class="form-label"
                >Parent Category</label
              >
              <select
                v-model="form.parent_id"
                id="region_code"
                class="form-select form-select-sm"
                :disabled="loading.form"
              >
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="name" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter category name."
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="description" class="form-label">Description</label>
              <input
                type="text"
                class="form-control"
                id="description"
                placeholder="Enter category description."
                v-model="form.description"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <div>
                <label for="icon_image" class="form-label">Icon Image</label>
                <input
                  class="form-control form-control-sm"
                  id="icon_image"
                  type="file"
                  @change="onIconImageChange"
                />
              </div>
              <a
                v-if="category.icon_image"
                :href="category.icon_image.storage_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style="font-size: 0.8rem"
                  ><i class="bi-image"></i> {{ category.icon_image.name }}</span
                >
              </a>
            </div>
            <div class="form-group">
              <div>
                <label for="banner_image" class="form-label"
                  >Banner Image</label
                >
                <input
                  class="form-control form-control-sm"
                  id="banner_image"
                  type="file"
                  @change="onBannerImageChange"
                />
              </div>
              <a
                v-if="category.banner_image"
                :href="category.banner_image.storage_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style="font-size: 0.8rem"
                  ><i class="bi-image"></i>
                  {{ category.banner_image.name }}</span
                >
              </a>
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Update Category" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import CategoryService from "@/services/staff/CategoryService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "ViewCategoryView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        parent_id: "",
        name: "",
        description: "",
        banner_image: "",
        icon_image: "",
      },
      category: {},
      success: "",
      errors: [],
      categories: [{ id: "", name: "Select Parent Category (Optional)" }],
    };
  },
  mounted() {
    CategoryService.getCategories(1, 100).then((response) => {
      this.categories = [
        this.categories[0],
        ...response.data.categories.data.map((item) => {
          return {
            id: `${item.id}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    CategoryService.getCategory(this.$route.params.id).then((response) => {
      this.category = response.data.category;
      this.form.parent_id =
        this.category.parent_id == null ? "" : this.category.parent_id;
      this.form.name = this.category.name;
      this.form.description = this.category.description;
    });
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      // Build payload.
      const formData = new FormData();
      formData.append("parent_id", this.form.parent_id);
      formData.append("name", this.form.name);
      formData.append("description", this.form.description);
      if (this.form.banner_image != "") {
        formData.append("banner_image", this.form.banner_image);
      }

      if (this.form.icon_image != "") {
        formData.append("icon_image", this.form.icon_image);
      }

      CategoryService.updateCategory(this.$route.params.id, formData)
        .then((response) => {
          this.success = response.data.message;
          this.category = response.data.category;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onBannerImageChange(e) {
      this.form.banner_image = e.target.files[0] || e.dataTransfer.files[0];
    },

    onIconImageChange(e) {
      this.form.icon_image = e.target.files[0] || e.dataTransfer.files[0];
    },
  },
};
</script>
