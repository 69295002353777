import { APIService } from './../authentication/APIService'

export default {
    getTransactions(
        page = 1,
        pageSize = 12,
        orderColumn = 'created_at',
        orderDirection = 'desc',
        accountCode = '',
        typeCode = '',
        startDate = '',
        endDate = ''
    ) {
        // Generate url
        let url = 'api/staff/transactions?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        if (accountCode) {
            url += '&account_code=' + accountCode;
        }

        if (typeCode) {
            url += '&type_code=' + typeCode;
        }

        if (startDate) {
            url += '&start_date=' + startDate;
        }

        if (endDate) {
            url += '&end_date=' + endDate;
        }

        return APIService.get(url);
    },

    recordTransaction(payload) {
        return APIService.post("api/staff/transactions", payload);
    },
}