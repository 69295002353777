<template>
  <div class="row justify-content-center mt-5 mb-5">
    <div class="col-md-4 text-center">
      <h1 class="playfair-display">Return a Purchase</h1>
      <p class="p-0 text-start">
        Items can returned and exchanged within 3 days and in as delivered state under the following conditions:
        <ul class="mt-3">
            <li>Item does not fit</li>
            <li>Wrong item delivered </li>
            <li>A defective item was delivered </li>
        </ul>
      </p>
      <p class="fw-bold text-center">
        054-566-1775 / 055-516-2374 <br />
        hello@thejeansshopgh.com
      </p>
    </div>
  </div>
</template>
      
    <script>
export default {
  name: "ReturnsView",
  components: {},
};
</script>