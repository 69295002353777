<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="New Coupon"
          :return_button="true"
          return_link="/staff/coupons"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="code" class="form-label">Code</label>
              <input
                type="text"
                class="form-control"
                id="code"
                placeholder="Enter coupon code."
                v-model="form.code"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="name" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter coupon name."
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="description" class="form-label">Description</label>
              <input
                type="text"
                class="form-control"
                id="description"
                placeholder="Enter coupon description."
                v-model="form.description"
                :disabled="loading.form"
              />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="type" class="form-label">Type</label>
                  <select
                    v-model="form.type"
                    id="type"
                    class="form-select form-select-sm"
                    :disabled="loading.form"
                  >
                    <option value="percentage">Percentage Off</option>
                    <option value="amount">Amount Off</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="value" class="form-label">Value</label>
                  <input
                    type="text"
                    class="form-control"
                    id="value"
                    placeholder="Enter coupon value. E.g. 10"
                    v-model="form.value"
                    :disabled="loading.form"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="max_usage" class="form-label">Max. Usage</label>
                  <input
                    type="text"
                    class="form-control"
                    id="max_usage"
                    placeholder="10"
                    v-model="form.max_usage"
                    :disabled="loading.form"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="free_delivery" class="form-label"
                    >Free Delivery</label
                  >
                  <select
                    v-model="form.free_delivery"
                    id="free_delivery"
                    class="form-select form-select-sm"
                    :disabled="loading.form"
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="status_code" class="form-label">Status</label>
              <select
                v-model="form.status_code"
                id="status_code"
                class="form-select form-select-sm"
                :disabled="loading.form"
              >
                <option>ACTIVE</option>
                <option>INACTIVE</option>
              </select>
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Add Coupon" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import CouponService from "@/services/staff/CouponService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddCouponView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        code: "",
        name: "",
        description: "",
        type: "percentage",
        value: "",
        max_usage: "",
        free_delivery: 1,
        status_code: "ACTIVE",
      },
      success: "",
      errors: [],
    };
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      CouponService.addCoupon(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.form.code = "";
          this.form.name = "";
          this.form.description = "";
          this.form.type = "percentage";
          this.form.value = "";
          this.form.max_usage = "";
          this.form.free_delivery = 1;
          this.form.status_code = "ACTIVE";
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
