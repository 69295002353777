<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="Regions"
          :return_button="false"
          :action_button="true"
          action_button_text="New Region"
          action_button_link="/staff/regions/add"
        ></page-header>
        <div class="row">
          <div class="col-md-12">
            <div v-if="!loading.page && regions.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Country</th>
                    <th scope="col">Name</th>
                    <th scope="col" class="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="region in regions" :key="region.id">
                    <th scope="row">{{ region.id }}</th>
                    <td>{{ region.country.name }}</td>
                    <td>
                      <b>{{ region.name }}</b>
                    </td>
                    <td class="text-end">
                      <a :href="`/staff/regions/${region.id}/view`">
                        <i class="bi-pencil-square fs-5 text-primary me-2"></i>
                      </a>
                      <!-- <i class="bi-trash fs-5 text-danger"></i> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav>
                <ul class="pagination justify-content-center mt-5">
                  <li v-if="pagination.currentPage > 1" class="page-item">
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      @click.prevent="changePage(pagination.currentPage - 1)"
                    >
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  <li
                    class="page-item"
                    v-for="page in pagination.totalPages"
                    :key="page"
                    :class="{ active: page == pagination.currentPage }"
                  >
                    <a
                      v-if="
                        page < 3 ||
                        page > pagination.totalPages - 3 ||
                        (page < pagination.currentPage + 3 &&
                          page > pagination.currentPage - 3)
                      "
                      class="page-link"
                      @click.prevent="changePage(page)"
                      >{{ page }}</a
                    >
                  </li>
                  <li
                    v-if="pagination.currentPage < pagination.totalPages"
                    class="page-item"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click.prevent="changePage(pagination.currentPage + 1)"
                    >
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <loader v-if="loading.page"> </loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import Loader from "@/components/Loader.vue";
import RegionService from "@/services/staff/RegionService";

export default {
  name: "ListRegionsView",
  components: {
    PageHeader,
    Loader,
  },

  data() {
    return {
      loading: {
        page: false,
      },
      regions: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },

  async mounted() {
    await this.getRecords();
    this.loading.page = false;
  },

  methods: {
    async getRecords(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await RegionService.getRegions(
        this.pagination.currentPage,
        this.pagination.perPage
      );

      this.regions = response.data.regions.data;
      this.pagination.totalRows = response.data.regions.total;
      this.loading.page = false;
      this.pagination.totalPages = response.data.regions.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getRecords();
      }
    },
  },
};
</script>
