var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"main"},[_c('main-header',{attrs:{"cart-count":_vm.cartCount,"wishlist-count":_vm.wishlistCount}}),(
        _vm.$store.getters['user/object'] &&
        ['MANAGER', 'ADMINISTRATOR', 'SUPER_ADMINISTRATOR'].includes(
          _vm.$store.getters['user/object'].role_code
        )
      )?_c('staff-header'):_vm._e(),_c('router-view',{class:'view ' +
        (_vm.$route.name == 'home' || _vm.$route.name == 'checkout' ? 'pb-0' : 'pb-5'),on:{"refreshCartCount":_vm.getCartCount,"refreshWishlistCount":_vm.getWishlistCount}})],1),_c('main-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }