import { APIService } from '../authentication/APIService'

export default {
    getAccounts(
        page = 1,
        pageSize = 12,
        orderColumn = null,
        orderDirection = null,
    ) {
        // Generate url
        let url = 'api/staff/accounts?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize + '&';

        if (orderColumn) {
            url += '&order_column=' + orderColumn;
        }

        if (orderDirection) {
            url += '&order_direction=' + orderDirection;
        }

        return APIService.get(url);
    },
}