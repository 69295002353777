import AuthenticationService from "@/services/authentication/AuthenticationService";

export default {
    namespaced: true,

    state: {
        object: null,
        token: null,
    },

    actions: {
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                AuthenticationService.login(payload)
                    .then(async ({ data }) => {

                        commit('SET_TOKEN', data.token);

                        commit('SET_OBJECT', data.user);

                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        register({ commit }, payload) {
            return new Promise((resolve, reject) => {
                AuthenticationService.register(payload)
                    .then(async ({ data }) => {

                        commit('SET_TOKEN', data.token);

                        commit('SET_OBJECT', data.user);

                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        logout({ commit }) {
            commit('SET_TOKEN', null);
            commit('SET_OBJECT', null);

        }
    },

    mutations: {
        SET_TOKEN(state, data) {
            state.token = data;
        },

        SET_OBJECT(state, data) {
            state.object = data;
        },
    },

    getters: {
        object: (state) => state.object,
        token: (state) => state.token,
    },
};
