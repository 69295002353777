<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <page-header title="View Order" :return_button="true" return_link="/staff/orders" :action_button="false"
          action_button_text="" action_button_link=""></page-header>
        <loader v-if="loading.page"></loader>
        <div v-else class="row">
          <div v-if="success" class="alert alert-success" role="alert">
            {{ success }}
          </div>
          <div class="col-md-12" style="font-size: 0.9rem">
            <div>
              Type: <b>{{ order.type_code }}</b>
            </div>
            <div>
              Customer:
              <b>{{ order.customer ? order.customer.email : "Guest" }}</b>
            </div>
            <div>
              Teller: <b>{{ order.teller ? order.teller.email : "N/a" }}</b>
            </div>
            <div>
              Status: <b>{{ order.status_code }}</b>
            </div>
            <div class="mt-3">
              <div class="row" v-for="(item, index) in order.items" :key="item.id">
                <div class="col-2 mb-3" style="padding-left: 0">
                  <img v-if="item.product_variation.product.images.length > 0" class="box-shadow-light w-100"
                    :src="item.product_variation.product.images[0].file.storage_link" alt="" />
                  <p v-else class="text-muted" style="font-size: 0.7rem">
                    No Image
                  </p>
                  <span class="badge badge-dark fw-bolder" style="
                      background-color: black;
                      position: absolute;
                      margin-left: -10px;
                      margin-top: -10px;
                      font-size: 0.7rem;
                      border-radius: 15px;
                    ">{{ item.quantity }}</span>
                </div>
                <div class="col-10 p-2 pt-1" style="padding-left: 15px !important">
                  <div class="row">
                    <div class="col-md-12 p-0 spread-cols-to-ends">
                      <div>
                        <span class="fw-bolder">{{ item.product_variation.product.name }}
                        </span>
                        <div>
                          <span style="">
                            <span class="fw-normal mt-2" style="font-size: 0.9rem">{{ item.product_variation.size.name
                              }}
                            </span>
                            <div v-if="item.product_variation.colour_code" class="box-shadow-light"
                              :style="`width: 30px; height: 18px; background-color: #${item.product_variation.colour_code}; margin-top: 5px`">
                            </div>
                          </span>
                        </div>
                      </div>
                      <div class="">
                        <div class="fw-bold text-end">
                          <span class="currency_code">GH₵ </span>{{
                            item.price *
                            item.quantity
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="index != order.items.length - 1" />
              </div>
            </div>

            <div class="mt-3" style="font-size: 0.9rem">
              <div class="spread-cols-to-ends" style="font-weight: bold">
                <div>Subtotal:</div>
                <div>
                  <span class="currency_code" style="font-size: 0.8rem">GH₵
                  </span>
                  {{ formatAmount(order.subtotal) }}
                </div>
              </div>
              <div class="spread-cols-to-ends" style="font-weight: 500">
                <div>Delivery Fee:</div>
                <div>
                  <span class="currency_code" style="font-size: 0.8rem">GH₵
                  </span>
                  {{ formatAmount(order.shipping_fee) }}
                </div>
              </div>
              <div class="spread-cols-to-ends" style="font-weight: 500">
                <div>Discount:</div>
                <div>
                  <span class="currency_code" style="font-size: 0.8rem">GH₵
                  </span>
                  {{ formatAmount(order.discount) }}
                </div>
              </div>
              <hr class="mt-1 mb-1" />
              <div class="text-end" style="font-weight: bolder; font-size: 1.3rem">
                <div>
                  <span class="currency_code" style="font-size: 0.8rem">GH₵
                  </span>
                  {{ formatAmount(order.total) }}
                </div>
              </div>
            </div>
            <div v-if="order.delivery_method_code == 'self_pick_up' && order.shipping_address != null" class="mt-3">
              <b>Pick Up Information</b>
              <div style="font-size: 0.8rem" class="mt-2">
                <div class="mt-1">
                  Information:
                  <b>
                    <div>
                      {{ order.shipping_address.first_name }}
                      {{ order.shipping_address.last_name }}
                    </div>
                    <div>{{ order.shipping_address.email }}</div>
                    <div>{{ order.shipping_address.phone }}</div>
                  </b>
                </div>
                <div class="mt-2">
                  Notes:
                  <b>{{ order.shipping_additional_details ?? "N/a." }}</b>
                </div>
              </div>
            </div>
            <div v-if="order.delivery_method_code == 'delivery'" class="mt-3">
              <b>Delivery Information</b>
              <div style="font-size: 0.8rem" class="mt-2">
                <div>
                  Country: <b>{{ order.shipping_country_code }}</b>
                </div>
                <div>
                  Region: <b>{{ order.shipping_region_code }}</b>
                </div>
                <div>
                  Zone: <b>{{ order.shipping_delivery_zone_code }}</b>
                </div>
                <div class="mt-1">
                  Address:
                  <b>
                    <div>
                      {{ order.shipping_address.first_name }}
                      {{ order.shipping_address.last_name }}
                    </div>
                    <div>{{ order.shipping_address.email }}</div>
                    <div>{{ order.shipping_address.phone }}</div>
                    <div>{{ order.shipping_address.address }}</div>
                  </b>
                </div>
                <div class="mt-2">
                  Notes:
                  <b>{{ order.shipping_additional_details ?? "N/a." }}</b>
                </div>
              </div>
            </div>
            <div class="mt-2 text-end">
              <button v-if="order.status_code == 'PROCESSING'" class="btn btn-dark" @click="printReceipt()">
                {{
                  loading.order
                    ? "Loading, please wait ..."
                    : "Print Receipt"
                }}
              </button>
              <button v-if="order.status_code == 'UNPAID'" class="btn btn-outline-dark ms-2"
                @click="updateOrderStatus('PROCESSING')">
                {{
                  loading.order
                    ? "Loading, please wait ..."
                    : "Manually Confirm Payment"
                }}
              </button>
              <button v-if="order.status_code == 'PROCESSING'" class="btn btn-outline-dark ms-2"
                @click="updateOrderStatus('READY_TO_SHIP')">
                {{
                  loading.order
                    ? "Loading, please wait ..."
                    : "Mark as Ready To Ship or Deliver"
                }}
              </button>
              <button v-if="order.status_code == 'READY_TO_SHIP'" class="btn btn-outline-dark ms-2"
                @click="updateOrderStatus('COMPLETED')">
                {{
                  loading.order
                    ? "Loading, please wait ..."
                    : "Mark as Completed"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import OrderService from "@/services/staff/OrderService";
import Loader from "@/components/Loader.vue";
import jsPDF from "jspdf";
export default {
  name: "ViewOrderView",
  mounted() {
    OrderService.getOrder(this.$route.params.id).then((response) => {
      const order = response.data.order;
      this.order = order;
      this.loading.page = false;
    });
  },
  data() {
    return {
      loading: {
        page: true,
        order: false,
      },
      order: {},
      success: "",
    };
  },
  methods: {
    formatAmount(number) {
      return new Intl.NumberFormat("en-GH", {}).format(number);
    },

    updateOrderStatus(status_code) {
      this.loading.order = true;
      OrderService.updateOrder(this.$route.params.id, {
        status_code: status_code,
      }).then((response) => {
        const order = response.data.order;
        this.success = response.data.message;
        this.order = order;
        this.loading.order = false;
      });
    },
    printReceipt() {
      this.docYPointer = 1;
      this.doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [3.14, 11],
      });

      this.doc.setFontSize(12);
      this.doc.text(
        "THE JEANS SHOP GH",
        this.doc.internal.pageSize.getWidth() / 2,
        0.2,
        { align: "center" }
      );
      this.doc.setFontSize(10);
      this.doc.text(
        "www.thejeansshopgh.com",
        this.doc.internal.pageSize.getWidth() / 2,
        0.4,
        { align: "center" }
      );
      this.doc.text(
        "IG - @thejeansshopgh",
        this.doc.internal.pageSize.getWidth() / 2,
        0.6,
        { align: "center" }
      );
      this.doc.text(
        "054-566-1775 / 055-516-2374",
        this.doc.internal.pageSize.getWidth() / 2,
        0.8,
        { align: "center" }
      );
      this.doc.text(
        "Ashale Botwe - Lakeside",
        this.doc.internal.pageSize.getWidth() / 2,
        1,
        { align: "center" }
      );
      this.doc.text(
        "-----------------------------------------------",
        this.doc.internal.pageSize.getWidth() / 2,
        1.15,
        { align: "center" }
      );
      for (let i = 0; i < this.order.items.length; i++) {
        // Get order item sku.
        this.docYPointer += 0.5;
        this.doc.text(
          i +
          1 +
          ". " +
          this.order.items[i].product_variation.product.name +
          " - " +
          this.order.items[i].product_variation.size.name +
          (this.order.items[i].product_variation.colour ? ", " + this.order.items[i].product_variation.colour.name : ""),
          0.1,
          this.docYPointer,
          {}
        );
        this.doc.text(
          this.pad(
            "GHS " +
            this.order.items[i].price * 1 +
            " x " +
            this.order.items[i].quantity
          ) +
          "...............  GHS " +
          this.order.items[i].price * this.order.items[i].quantity,
          0.1,
          this.docYPointer + 0.2,
          {}
        );
      }
      this.doc.text(
        this.pad("Sub-Total:") + "..................  GHS " + this.formatAmount(this.order.total),
        0.1,
        this.docYPointer + 1,
        {}
      );
      this.doc.text(
        this.pad("Discount:") + "..................  GHS " + 0,
        0.1,
        this.docYPointer + 1.2,
        {}
      );
      this.doc.text(
        this.pad("Total:") + "..................  GHS " + this.formatAmount(this.order.total),
        0.1,
        this.docYPointer + 1.4,
        {}
      );
      this.doc.text(
        "Paid By: " + this.order.type_code,
        0.1,
        this.docYPointer + 1.7,
        {}
      );
      this.doc.text(
        "Customer / Teller: " + (this.order.user_id != null ? this.order.customer.email : (this.order.teller_id != null ? this.order.teller.email : 'Guest')),
        0.1,
        this.docYPointer + 1.9,
        {}
      );
      this.doc.text(
        "Order Ref: " + this.order.id,
        0.1,
        this.docYPointer + 2.1,
        {}
      );

      this.doc.text(
        "Thank you for shopping with us!",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 2.6,
        { align: "center" }
      );
      this.doc.setFontSize(8);
      this.doc.text(
        "Developed by Nusoft Group",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 3,
        { align: "center" }
      );
      this.doc.text(
        "hello@nusoftgroup.com",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 3.15,
        { align: "center" }
      );
      this.doc.text(
        ".",
        this.doc.internal.pageSize.getWidth() / 2,
        this.docYPointer + 5,
        { align: "center" }
      );
      this.doc.save(this.order.id + ".pdf");
    },

    pad(string) {
      var padding = "  ";
      for (let index = 0; index < 20 - string.length; index++) {
        padding += "..";
      }
      return string + padding;
    },
  },
  components: {
    PageHeader,
    Loader,
  },
};
</script>
