<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <page-header
          title="Accounts"
          :return_button="false"
          :action_button="true"
          action_button_text="Record Transaction"
          action_button_link="/staff/transactions/add"
        ></page-header>
        <div class="row">
          <div class="col-md-12 mb-3 p-2 text-start box-shadow-light">
            <div class="row">
              <div class="col-md-3 p-1">
                <select
                  placeholder="All Accounts "
                  @change="getRecords"
                  class="form-select w-100"
                  style="border-radius: 0px; height: 34px"
                  v-model="filter.account_code"
                >
                  <option value="">All Accounts</option>
                  <option
                    v-for="account in summaries"
                    :key="account.id"
                    :value="account.code"
                  >
                    {{ account.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <select
                  placeholder="Credits & Debits "
                  @change="getRecords"
                  class="form-select w-100"
                  style="border-radius: 0px; height: 34px"
                  v-model="filter.type_code"
                >
                  <option value="">Credits & Debits</option>
                  <option value="Cr">Credits</option>
                  <option value="Dr">Debits</option>
                </select>
              </div>
              <div class="col-md-3 p-1">
                <input
                  v-model="filter.start_date"
                  type="date"
                  class="form-control text-center fw-bolder"
                  placeholder="Start Date"
                  @change="getRecords"
                />
              </div>
              <div class="col-md-3 p-1">
                <input
                  v-model="filter.end_date"
                  type="date"
                  class="form-control text-center fw-bolder"
                  placeholder="End Date"
                  @change="getRecords"
                />
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-3 p-2 text-start box-shadow-light">
            <div class="row">
              <div
                v-for="account in summaries"
                :key="account.id"
                class="col-md-3 p-1"
              >
                <h5 class="playfair-display mb-1">{{ account.name }}</h5>
                <div style="font-size: 0.8rem; font-weight: 600">
                  Total:
                  <span
                    style="font-size: 1rem"
                    v-if="account.balance > 0"
                    class="text-success"
                    ><span class="currency_code">GH₵ </span
                    >{{ formatAmount(account.balance) }}</span
                  ><span
                    style="font-size: 1rem"
                    v-else-if="account.balance < 0"
                    class="text-danger"
                    ><span class="currency_code">GH₵ </span
                    >{{ formatAmount(account.balance) }}</span
                  ><span style="font-size: 1rem" v-else
                    ><span class="currency_code">GH₵ </span
                    >{{ formatAmount(account.balance) }}</span
                  >
                </div>
                <div style="font-size: 0.8rem; font-weight: 600">
                  Period:
                  <span
                    style="font-size: 1rem"
                    v-if="account.period > 0"
                    class="text-success"
                    ><span class="currency_code">GH₵ </span
                    >{{ formatAmount(account.period) }}</span
                  ><span
                    style="font-size: 1rem"
                    v-else-if="account.period < 0"
                    class="text-danger"
                    ><span class="currency_code">GH₵ </span
                    >{{ formatAmount(account.period) }}</span
                  ><span style="font-size: 1rem" v-else
                    ><span class="currency_code">GH₵ </span
                    >{{ formatAmount(account.period) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div v-if="!loading.page && transactions.length > 0">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Account</th>
                    <th scope="col">Date and Time.</th>
                    <th scope="col">Description</th>
                    <th scope="col" class="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="transaction in transactions" :key="transaction.id">
                    <th scope="row">{{ transaction.id }}</th>
                    <td class="fw-bolder">{{ transaction.account.name }}</td>
                    <td class="text-no-wrap">
                      {{
                        moment(transaction.created_at).format(
                          "MMMM Do YYYY, h:mm a"
                        )
                      }}
                    </td>
                    <td>{{ transaction.description }}</td>
                    <td class="text-end">
                      <div
                        :class="
                          transaction.amount < 0
                            ? 'text-danger'
                            : 'text-success'
                        "
                      >
                        <span class="currency_code">GH₵</span>
                        <b>
                          {{
                            transaction.amount < 0
                              ? transaction.amount * -1
                              : transaction.amount
                          }}</b
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav>
                <ul class="pagination justify-content-center mt-5">
                  <li v-if="pagination.currentPage > 1" class="page-item">
                    <a
                      class="page-link"
                      href="#"
                      aria-label="Previous"
                      @click.prevent="changePage(pagination.currentPage - 1)"
                    >
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  <li
                    class="page-item"
                    v-for="page in pagination.totalPages"
                    :key="page"
                    :class="{ active: page == pagination.currentPage }"
                  >
                    <a
                      v-if="
                        page < 3 ||
                        page > pagination.totalPages - 3 ||
                        (page < pagination.currentPage + 3 &&
                          page > pagination.currentPage - 3)
                      "
                      class="page-link"
                      @click.prevent="changePage(page)"
                      >{{ page }}</a
                    >
                  </li>
                  <li
                    v-if="pagination.currentPage < pagination.totalPages"
                    class="page-item"
                  >
                    <a
                      class="page-link"
                      aria-label="Next"
                      @click.prevent="changePage(pagination.currentPage + 1)"
                    >
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <center-message v-else-if="transactions.length == 0"
              ></center-message
            >
            <loader v-if="loading.page"> </loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import Loader from "@/components/Loader.vue";
import TransactionService from "@/services/staff/TransactionService";
import CenterMessage from "@/components/CenterMessage.vue";

export default {
  name: "ListTransactionsView",
  components: {
    PageHeader,
    Loader,
    CenterMessage,
  },

  data() {
    return {
      loading: {
        page: false,
      },
      summaries: [],
      transactions: [],
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
      sort: {
        column: "created_at",
        direction: "desc",
      },
      filter: {
        account_code: "",
        type_code: "",
        start_date: "",
        end_date: "",
      },
      options: {
        accounts: [{ code: "", name: "All Accounts" }],
      },
    };
  },

  async mounted() {
    await this.getRecords();
    this.loading.page = false;
  },

  methods: {
    async getRecords(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await TransactionService.getTransactions(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.sort.column,
        this.sort.direction,
        this.filter.account_code,
        this.filter.type_code,
        this.filter.start_date,
        this.filter.end_date
      );

      this.summaries = response.data.summaries;
      this.transactions = response.data.transactions.data;
      this.pagination.totalRows = response.data.transactions.total;
      this.loading.records = false;
      this.loading.page = false;
      this.pagination.totalPages = response.data.transactions.last_page;
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getRecords();
      }
    },

    formatAmount(number) {
      return new Intl.NumberFormat("en-GH", {}).format(number);
    },
  },
};
</script>
