<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="New Delivery Zone"
          :return_button="true"
          return_link="/staff/delivery-zones"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>
            <div class="form-group">
              <label for="region_code" class="form-label">Region</label>
              <select
                v-model="form.region_code"
                id="region_code"
                class="form-select form-select-sm"
                :disabled="loading.form"
              >
                <option
                  v-for="region in regions"
                  :key="region.code"
                  :value="region.code"
                >
                  {{ region.description }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="name" class="form-label">Zone Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter Zone Name. E.g ZONE 1"
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>

            <div class="form-group">
              <label for="code" class="form-label">Zone Code</label>
              <input
                type="text"
                class="form-control"
                id="code"
                placeholder="Enter Zone Code. E.g ZONE1"
                v-model="form.code"
                :disabled="loading.form"
              />
            </div>
            <div class="form-group">
              <label for="locations" class="form-label">Locations</label>
              <textarea
                class="form-control"
                id="locations"
                placeholder="Enter Zone locations"
                v-model="form.locations"
                :disabled="loading.form"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="cost" class="form-label">Delivery Cost</label>
              <input
                type="text"
                class="form-control"
                id="cost"
                placeholder="Enter Delivery Cost. E.g 50"
                v-model="form.cost"
                :disabled="loading.form"
              />
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{
                  loading.form ? "Loading, please wait..." : "Add Delivery Zone"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import DeliveryZoneService from "@/services/staff/DeliveryZoneService";
import RegionService from "@/services/staff/RegionService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddRegionView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        region_code: "",
        name: "",
        code: "",
        locations: "",
        cost: "",
      },
      success: "",
      errors: [],
      regions: [{ description: "Kindly select a region.", code: "" }],
    };
  },
  mounted() {
    RegionService.getRegions(1, 100).then((response) => {
      this.regions = [
        this.regions[0],
        ...response.data.regions.data.map((item) => {
          return {
            code: `${item.code}`,
            description: `${item.name}, ${item.country.name}`,
          };
        }),
      ];
    });
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      DeliveryZoneService.addDeliveryZone(this.form)
        .then((response) => {
          this.success = response.data.message;
          this.form.region_code = "";
          this.form.name = "";
          this.form.code = "";
          this.form.locations = "";
          this.form.cost = "";
          
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
  },
};
</script>
