import axios from "axios";
import { CaretakerApiService } from "./CaretakerAPIService";

export const AuthClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true, // required to handle the CSRF token
});

export default {
    async login(payload) {
        await AuthClient.get("/sanctum/csrf-cookie");
        return AuthClient.post("/api/login", {
            email: payload.email,
            password: payload.password,
            device_name: navigator.userAgent
        });
    },

    async register(payload) {
        return CaretakerApiService.post("/api/register", {
            name: payload.name,
            email: payload.email,
            phone: payload.phone,
            password: payload.password,
            password_confirmation: payload.password_confirmation,
            device_name: navigator.userAgent
        });
    }
}