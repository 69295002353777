<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <page-header
          title="New Product"
          :return_button="true"
          return_link="/staff/products"
          :action_button="false"
          action_button_text=""
          action_button_link=""
        ></page-header>
        <div class="row mt-3">
          <div class="col-md-12">
            <div v-if="success" class="alert alert-success" role="alert">
              {{ success }}
            </div>
            <div
              v-if="errors.length > 0"
              class="alert alert-danger"
              role="alert"
            >
              <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </div>

            <div class="form-group">
              <label for="name" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Enter category name."
                v-model="form.name"
                :disabled="loading.form"
              />
            </div>

            <div class="form-group">
              <label for="description" class="form-label">Description</label>
              <input
                type="text"
                class="form-control"
                id="description"
                placeholder="Enter category description."
                v-model="form.description"
                :disabled="loading.form"
              />
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="category_id" class="form-label">Category</label>
                  <select
                    v-model="form.category_id"
                    id="category_id"
                    class="form-select form-select-sm"
                    :disabled="loading.form"
                  >
                    <option
                      v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
                    >
                      {{ category.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="price" class="form-label">Price</label>
                  <input
                    type="text"
                    class="form-control"
                    id="price"
                    placeholder="0.00."
                    v-model="form.retail_price"
                    :disabled="loading.form"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div>
                <label for="images" class="form-label">Images</label>
                <input
                  class="form-control form-control-sm"
                  id="images"
                  type="file"
                  ref="images"
                  @change="onImageChange"
                  multiple
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <label for="images" class="form-label mb-1">Sizes</label>
                    <div>
                      <div
                        v-for="size in sizes"
                        :key="size.code"
                        class="col-md-5 form-check form-check-inline"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="form.sizes"
                          :value="size.code"
                          :id="`sizeCheck${size.code}`"
                        />
                        <label
                          class="form-check-label"
                          :for="`sizeCheck${size.code}`"
                        >
                          <div style="font-size: 0.9rem; font-weight: 600">
                            {{ size.name }}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <div>
                    <label for="images" class="form-label mb-1">Colours</label>
                    <div>
                      <div
                        v-for="colour in colours"
                        :key="colour.code"
                        class="form-check form-check-inline mb-1"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :true-value="[]"
                          v-model="form.colours"
                          :value="colour.code"
                          :id="`colourCheck${colour.code}`"
                        />
                        <label
                          class="form-check-label"
                          :for="`colourCheck${colour.code}`"
                        >
                          <div
                            class="box-shadow-light mt-1"
                            :style="`width: 30px; height: 18px; background-color: #${colour.code};`"
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end">
              <button
                @click.prevent="onSubmit"
                type="button"
                class="btn btn-outline-primary mt-3 w-100"
                :disabled="loading.form"
              >
                {{ loading.form ? "Loading, please wait..." : "Add Product" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import CategoryService from "@/services/staff/CategoryService";
import ColourService from "@/services/staff/ColourService";
import ProductService from "@/services/staff/ProductService";
import SizeService from "@/services/staff/SizeService";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "AddProductView",
  components: {
    PageHeader,
  },
  data() {
    return {
      loading: {
        form: false,
      },
      form: {
        name: "",
        description: "",
        category_id: "",
        retail_price: "",
        images: "",
        sizes: [],
        colours: [],
      },
      success: "",
      errors: [],
      categories: [{ id: "", name: "Select Category" }],
      sizes: [],
      colours: [],
    };
  },
  mounted() {
    CategoryService.getCategories(1, 100).then((response) => {
      this.categories = [
        this.categories[0],
        ...response.data.categories.data.map((item) => {
          return {
            id: `${item.id}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    ColourService.getColours(1, 100).then((response) => {
      this.colours = [
        ...response.data.colours.data.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name}`,
          };
        }),
      ];
    });

    SizeService.getSizes(1, 100).then((response) => {
      this.sizes = [
        ...response.data.sizes.data.map((item) => {
          return {
            code: `${item.code}`,
            name: `${item.name}`,
          };
        }),
      ];
    });
  },
  methods: {
    onSubmit() {
      this.loading.form = true;

      // Clear success and error message.
      this.success = "";
      this.errors = [];

      // Build payload.
      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("category_id", this.form.category_id);
      formData.append("description", this.form.description);
      formData.append("retail_price", this.form.retail_price);

      // Images
      if (this.form.images != "") {
        for (let i = 0; i < this.form.images.length; i++) {
          formData.append("images[" + i + "]", this.form.images[i]);
        }
      }

      // Sizes
      if (this.form.sizes) {
        for (let i = 0; i < this.form.sizes.length; i++) {
          formData.append("sizes[" + i + "]", this.form.sizes[i]);
        }
      }

      // Images
      if (this.form.colours) {
        for (let i = 0; i < this.form.colours.length; i++) {
          formData.append("colours[" + i + "]", this.form.colours[i]);
        }
      }

      ProductService.addProduct(formData)
        .then((response) => {
          this.success = response.data.message;
          this.form.name = "";
          this.form.description = "";
          this.form.category_id = "";
          this.form.retail_price = "";
          this.form.images = "";
          this.form.sizes = [];
          this.form.colours = [];
          this.$refs.images.value = null;
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    onImageChange(e) {
      this.form.images = e.target.files || e.dataTransfer.files;
    },
  },
};
</script>
