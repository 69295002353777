import axios from "axios";
import TokenService from "./TokenService";
import store from '@/store';
import router from "@/router";


export const CaretakerApiService = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});


// interceptors to add token
CaretakerApiService.interceptors.request.use(
    async (config) => {
        const token = await TokenService.getAccessToken('caretaker');
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// interceptors to regenerate token if expired
CaretakerApiService.interceptors.response.use(
    undefined, async (error) => {
        if (error.response.status === 419 || error.response.status === 401) {
            // Delete current caretaker token and retry
            await store.dispatch('caretaker/logout', null);
            await router.push('/login');

            const originalRequestConfig = error.config;
            return CaretakerApiService.request(originalRequestConfig);
        }

        // if (condition) {
            
        // }
        return Promise.reject(error);
    }
);
