import { APIService } from './../authentication/APIService'

export default {
    getCategories(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/categories?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getCategory(categoryId) {
        return APIService.get("api/staff/categories/" + categoryId);
    },

    addCategory(payload) {
        return APIService.post("api/staff/categories", payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },

    updateCategory(categoryId, payload) {
        return APIService.post("api/staff/categories/" + categoryId, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    },
}