<template>
  <ul class="nav justify-content-center bg-primary show-desktop-only staff-header">
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/users">Users</a>
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/categories">Categories</a>
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/colours">Colours</a>
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/sizes">Sizes</a>
    </li>
    <li v-if="userHasClearance(3)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/products">Products</a>
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/countries">Countries</a>
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/regions">Regions</a>
    </li>

    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/delivery-zones"
        >Delivery Zones</a
      >
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/coupons">Coupons</a>
    </li>
    <li v-if="userHasClearance(3)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/orders">Orders</a>
    </li>

    <!-- <li class="nav-item">
      <a class="nav-link fc-white" href="/staff/refunds">Refunds</a>
    </li> -->
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/accounts">Accounts</a>
    </li>
    <li v-if="userHasClearance(4)" class="nav-item">
      <a class="nav-link fc-white" href="/staff/settings">Settings</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "StaffHeader",
};
</script>