import { APIService } from './../authentication/APIService'

export default {
    getRefunds(
        page = 1,
        pageSize = 12
    ) {
        // Generate url
        let url = 'api/account/refunds?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);

    },

    getRefund(refundId) {
        return APIService.get("api/account/refunds/" + refundId);
    },

    addRefund(payload) {
        return APIService.post("api/account/refunds", payload);
    },

    updateRefund(refundId, payload) {
        return APIService.post("api/account/refunds/" + refundId, payload);
    }
}