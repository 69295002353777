var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading.page)?_c('loader'):_c('div',[_c('h1',{class:'playfair-display text-center mt-4 mb-4'},[_vm._v("Our Catalog")]),_c('div',{staticClass:"row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.categories),function(category){return _c('a',{key:category.id,staticClass:"col-5 col-lg-5 m-2 bg-grey collection text-center fc-white",style:({
              textDecoration: `none`,
              backgroundImage: `url(${
                category.banner_image != null
                  ? category.banner_image.storage_link
                  : ''
              })`,
              backgroundColor: category.banner_image != null
                  ? ''
                  : '#000000 !important'
            }),attrs:{"href":`/shop?category=${category.id}`}},[_c('div',[_c('span',{staticClass:"colletion-text"},[_vm._v(_vm._s(category.name))])])])}),0)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }