<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-7">
        <page-header title="Products" :return_button="false" :action_button="true" action_button_text="New Product"
          action_button_link="/staff/products/add">
        </page-header>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <div class="form-group">
                <input type="text" class="form-control" id="name" placeholder="Enter Product to Search."
                  v-model="search" />
              </div>
            </div>
            <div v-if="!loading.page && products.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Status</th>
                    <th scope="col">Name</th>
                    <th scope="col">Category</th>
                    <th scope="col">Views</th>
                    <th scope="col" class="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in products" :key="product.id">
                    <th scope="row">{{ product.id }}</th>
                    <td>
                      <span v-if="product.status_code == 'LIVE'" class="badge text-bg-success">Live</span>
                      <span v-if="product.status_code == 'INACTIVE'" class="badge text-bg-dark">Inactive</span>
                    </td>
                    <td class="fw-bolder">{{ product.name }}</td>
                    <td>
                      {{ product.category.name }}
                      <div>

                      </div>
                    </td>
                    <td>{{ product.views }}</td>
                    <td class="text-end">
                      <a v-if="product.status_code == 'INACTIVE'" href=""><i
                          class="bi-check-circle-fill fs-5 text-success me-2"
                          @click.prevent="updateProduct(product, 'LIVE')"></i></a>
                      <a v-if="product.status_code == 'LIVE'" href=""><i
                          class="bi-exclamation-circle-fill fs-5 text-warning me-2"
                          @click.prevent="updateProduct(product, 'INACTIVE')"></i></a>
                      <a :href="`/staff/products/${product.id}/view`">
                        <i class="bi-pencil-square fs-5 text-primary me-2"></i>
                      </a>
                      <a href=""><i class="bi-trash fs-5 text-danger"
                          @click.prevent="deleteProduct(product.id)"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav>
                <ul class="pagination justify-content-center mt-5">
                  <li v-if="pagination.currentPage > 1" class="page-item">
                    <a class="page-link" href="#" aria-label="Previous"
                      @click.prevent="changePage(pagination.currentPage - 1)">
                      <span aria-hidden="true">«</span>
                    </a>
                  </li>
                  <li class="page-item" v-for="page in pagination.totalPages" :key="page"
                    :class="{ active: page == pagination.currentPage }">
                    <a v-if="
                      page < 3 ||
                      page > pagination.totalPages - 3 ||
                      (page < pagination.currentPage + 3 &&
                        page > pagination.currentPage - 3)
                    " class="page-link" @click.prevent="changePage(page)">{{ page }}</a>
                  </li>
                  <li v-if="pagination.currentPage < pagination.totalPages" class="page-item">
                    <a class="page-link" aria-label="Next" @click.prevent="changePage(pagination.currentPage + 1)">
                      <span aria-hidden="true">»</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <center-message v-else-if="products.length == 0 && loading == false">No products found.</center-message>

            <loader v-if="loading.page"> </loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layout/navigation/PageHeader.vue";
import Loader from "@/components/Loader.vue";
import ProductService from "@/services/staff/ProductService";
import CenterMessage from "@/components/CenterMessage.vue";
import { formatErrors } from "@/utils/helpers";

export default {
  name: "ListProductsView",
  components: {
    PageHeader,
    Loader,
    CenterMessage,
  },

  data() {
    return {
      loading: {
        page: false,
      },
      products: [],
      search: '',
      pagination: {
        currentPage: 1,
        perPage: 12,
        totalRows: 0,
        totalPages: 0,
      },
    };
  },

  async mounted() {
    await this.getRecords();
    this.loading.page = false;
  },

  methods: {
    async getRecords(search) {
      this.loading.page = true;
      if (search === true) {
        this.pagination.currentPage = 1;
      }

      let response = await ProductService.getProducts(
        this.pagination.currentPage,
        this.pagination.perPage,
        this.search
      );

      this.products = response.data.products.data;
      this.pagination.totalRows = response.data.products.total;
      this.loading.page = false;
      this.pagination.totalPages = response.data.products.last_page;
    },

    deleteProduct(productId) {
      ProductService.deleteProduct(productId)
        .then((response) => {
          this.success = response.data.message;
          this.getRecords();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    updateProduct(product, status_code) {
      ProductService.updateProduct(product.id, {
        category_id: product.category_id,
        name: product.name,
        description: product.description,
        retail_price: product.retail_price,
        discount: product.discount,
        status_code: status_code
      })
        .then((response) => {
          this.success = response.data.message;
          this.getRecords();
        })
        .catch((error) => {
          let scope = this;
          scope.errors = formatErrors(error);
        })
        .finally(() => {
          this.loading.form = false;
        });
    },

    changePage(page) {
      if (this.pagination.currentPage != page) {
        this.pagination.currentPage = page;
        this.getRecords();
      }
    },
  },
  watch: {
    search: {
      handler: function () {
        this.getRecords(true);
      },
    },
  },
};
</script>
