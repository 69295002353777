import { APIService } from './../authentication/APIService'

export default {
    getCountries(
        page = 1,
        pageSize = 12,
    ) {
        // Generate url
        let url = 'api/staff/countries?';

        // Mandatory parameters
        url += 'page=' + page + '&';
        url += 'per_page=' + pageSize;

        return APIService.get(url);
    },

    getCountry(countryId) {
        return APIService.get("api/staff/countries/" + countryId);
    },

    addCountry(payload) {
        return APIService.post("api/staff/countries", payload);
    },

    updateCountry(countryId, payload) {
        return APIService.patch("api/staff/countries/" + countryId, payload);
    },
}