<template>
  <div class="row justify-content-center mt-10 mb-5">
    <div class="col-md-4 text-center">
      <h1 class="playfair-display">Contact Us</h1>
      <p class="fw-bold">
        054-566-1775 / 055-516-2374 <br />
        hello@thejeansshopgh.com
      </p>
    </div>
  </div>
</template>
    
  <script>
export default {
  name: "ContactView",
  components: {},
};
</script>